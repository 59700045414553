import React, { useEffect, useState } from 'react'
import './index.scss'
import {
  Box,
  Container,
  Grid,
  Header,
  Modal,
  SpaceBetween,
  Tabs,
} from '@amzn/awsui-components-react'
import 'ace-builds/css/ace.css'
import 'ace-builds/css/theme/dawn.css'
import JsonCodeEditor from './JsonCodeEditor'
import { GetTreatment } from '../../apis/SAFESApi'
import {
  convertFraudCheckStepMapToEdges,
  convertFraudCheckStepMapToNodes,
  convertStepsToNodes,
  validateTree,
} from './TreeUtil'
import {
  convertObjectToParams,
  convertParamsToObject,
  FraudCheckStep,
  revert,
  ruleNameUnselected,
  setClickedSelectedRuleName,
  setCurEditRule,
  setEdges,
  setInitEdges,
  setInitNodes,
  setInitRuleParams,
  setInitStepJsonVal,
  setNodes,
  setRuleParams,
  setStepJsonVal,
} from '../../store/slice/LayoutFlowSlice'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../store/store'
import { FraudCheckParameters } from '../../util/FraudCheckParameters'
import LayoutFlow from './LayoutFlow'
import Button from '@amzn/awsui-components-react/polaris/button'
import { GetRequestsForTreatment, Request, SubmitForApproval } from '../../apis/ApprovalApi'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { CLIENT_SEARCH_PARAM, RULESET_SEARCH_PARAM } from '../topNavigation/TopNavigation'
import _ from 'lodash'
import { validateSortTree } from '../../util/RulesetValidator'
import { getValidParameters } from '../../util/ParameterValidator'

export default function TreePage() {
  const userAlias = useSelector((state: RootState) => state.userInfo.userAlias)
  const ruleParamsJson = useSelector((state: RootState) => state.layoutFlow.ruleParams)
  const ruleParams = convertObjectToParams(ruleParamsJson)
  const stepJsonVal = useSelector((state: RootState) => state.layoutFlow.stepJsonVal)
  const initStepJsonVal = useSelector((state: RootState) => state.layoutFlow.initStepJsonVal)
  const [steps, setSteps] = useState<FraudCheckStep[]>([])
  const [nodesMap, setNodesMap] = useState(new Map())
  const [version, setVersion] = useState<number>(-1)
  const [lastUpdateRequest, setLastUpdateRequest] = useState<Request>()
  const [parameterEditor, setParameterEditor] = useState(<div />)
  const [showRevertModal, setShowRevertModal] = useState(false)
  const clickedSelectedRuleName = useSelector(
    (state: RootState) => state.layoutFlow.clickedSelectedRuleName
  )
  const { tab } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  function onTabChanged(ev) {
    if (ev.detail.activeTabId === 'diagram') {
      const updatedNodes = convertStepsToNodes(stepJsonVal)
      const updatedInitNodes = convertFraudCheckStepMapToNodes(updatedNodes)
      const updatedInitEdges = convertFraudCheckStepMapToEdges(updatedNodes)
      dispatch(setNodes([...updatedInitNodes]))
      dispatch(setEdges([...updatedInitEdges]))
      dispatch(setRuleParams(convertParamsToObject(fetchRuleParams(stepJsonVal))))
      dispatch(setClickedSelectedRuleName(ruleNameUnselected))
      dispatch(setCurEditRule([clickedSelectedRuleName, false]))
    }
    navigate(
      `/ruleset/${ev.detail.activeTabId}?client=${searchParams.get(
        CLIENT_SEARCH_PARAM
      )}&ruleset=${searchParams.get(RULESET_SEARCH_PARAM)}`
    )
  }

  function onSubmitUpdate() {
    const [updatedValid, updatedFirstStep, updatedSteps, errorMsg] = validateTree(
      stepJsonVal,
      ruleParams,
      ruleParamsJson
    )
    if (updatedValid) {
      console.log('valid and submit')
      submitChangedRuleset({
        proposedFirstStepName: updatedFirstStep,
        proposedJson: JSON.stringify(JSON.stringify(updatedSteps)),
        currentJson: JSON.stringify(JSON.stringify(initStepJsonVal)),
      })
      dispatch(setClickedSelectedRuleName(ruleNameUnselected))
    }
  }

  const submitChangedRuleset = (props) => {
    SubmitForApproval({
      requester: userAlias,
      treatmentId: searchParams.get(RULESET_SEARCH_PARAM) || '',
      clientId: searchParams.get(CLIENT_SEARCH_PARAM) || '',
      currentJson: props.currentJson,
      proposedJson: props.proposedJson,
      proposedFirstStepName: props.proposedFirstStepName,
      sourceVersion: version,
    })
      .then((response) => navigate(`/request/${response}`))
      .catch((err) => console.error('Problem: cannot submit ruleset for approval', err))
  }

  const fetchDataSteps = () => {
    console.log('in fetch step ', searchParams.get(RULESET_SEARCH_PARAM) || '')
    GetTreatment({
      userAlias,
      clientId: searchParams.get(CLIENT_SEARCH_PARAM) || '',
      treatmentName: searchParams.get(RULESET_SEARCH_PARAM) || '',
    })
      .then((response) => {
        setSteps(response.steps)
        setVersion(response.version)
      })
      .catch((exception) => console.error('Problem: cannot get steps', exception))
  }

  const fetchRequests = () => {
    GetRequestsForTreatment({
      userAlias,
      clientId: searchParams.get(CLIENT_SEARCH_PARAM) || '',
      treatmentId: searchParams.get(RULESET_SEARCH_PARAM) || '',
    })
      .then((response) => {
        if (response) {
          response.sort((a, b) => b.lastModifiedDate.localeCompare(a.lastModifiedDate))
          setLastUpdateRequest(response[0])
        }
      })
      .catch((exception) => console.error('Problem: cannot get steps', exception))
  }

  function fetchRuleParams(stepList) {
    const param_map = new Map<string, Map<string, string[]>>()
    FraudCheckParameters.forEach((value, key) => {
      const rule_param = new Map<string, string[]>()
      value.forEach((paramVal, paramKey) => {
        rule_param.set(paramKey, paramVal)
      })
      param_map.set(key, rule_param)
    })

    stepList.map((step) => {
      const rule_param = param_map.has(step.ruleName)
        ? param_map.get(step.ruleName)
        : new Map<string, string[]>()
      if (step && step.parameters) {
        Object.keys(step.parameters!).map((k) => {
          rule_param!.set(k, step.parameters[k])
        })
      }
      param_map.set(step.ruleName, rule_param!)
    })
    return param_map
  }

  const disableSubmit = () => {
    const [isTreeValid, sortedSteps, rulesetMessage] = validateSortTree(stepJsonVal)
    const [isParamsValid, fixedSteps, paramMessage] = getValidParameters(
      ruleParamsJson,
      ruleParams,
      sortedSteps
    )

    return (
      lastUpdateRequest?.status === 'PENDING' ||
      _.isEqual(initStepJsonVal, sortedSteps) ||
      !(isTreeValid && isParamsValid)
    )
  }

  useEffect(() => {
    if (searchParams.has(CLIENT_SEARCH_PARAM) && searchParams.has(RULESET_SEARCH_PARAM)) {
      fetchDataSteps()
      fetchRequests()
    }
  }, [searchParams])
  useEffect(() => {
    setNodesMap(convertStepsToNodes(steps))
    const rule_params = convertParamsToObject(fetchRuleParams(steps))
    dispatch(setRuleParams(rule_params))
    dispatch(setInitRuleParams(rule_params))
  }, [steps])
  useEffect(() => {
    const nodes = convertFraudCheckStepMapToNodes(nodesMap)
    const edges = convertFraudCheckStepMapToEdges(nodesMap)
    dispatch(setInitNodes(nodes))
    dispatch(setNodes(nodes))
    dispatch(setInitEdges(edges))
    dispatch(setEdges(edges))
    const values: FraudCheckStep[] = Array.from(nodesMap.values())
    values.map((rule) => {
      if (ruleParams.has(rule.ruleName)) {
        rule.parameters = ruleParamsJson[rule.ruleName]
      }
    })
    dispatch(setInitStepJsonVal(values))
    dispatch(setStepJsonVal(values))
  }, [nodesMap])

  const revertModal = (
    <Modal
      onDismiss={() => setShowRevertModal(false)}
      visible={showRevertModal}
      closeAriaLabel="Close modal"
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={() => setShowRevertModal(false)}>
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                dispatch(revert())
                dispatch(setClickedSelectedRuleName(ruleNameUnselected))
                dispatch(setCurEditRule(['', false]))
                setShowRevertModal(false)
              }}
            >
              Revert
            </Button>
          </SpaceBetween>
        </Box>
      }
      header="Revert edits"
    >
      Revert all edits made to the ruleset this session? This cannot be undone.
    </Modal>
  )

  return (
    <Box margin="xxl" padding="xxl">
      {revertModal}
      <Grid gridDefinition={[{ colspan: 7, offset: 2 }, { colspan: 3 }]}>
        <Container
          header={
            <Header
              variant="h2"
              actions={
                <SpaceBetween direction="horizontal" size="xs">
                  {lastUpdateRequest ? (
                    <Button
                      variant="link"
                      onClick={() => {
                        navigate(`/request/${lastUpdateRequest?.requestId}`)
                      }}
                    >
                      {lastUpdateRequest.status === 'PENDING'
                        ? ' Pending update request'
                        : 'Recent update request'}
                    </Button>
                  ) : (
                    <Button variant="link" disabled>
                      No update requests
                    </Button>
                  )}
                  <Button
                    onClick={() => setShowRevertModal(true)}
                    disabled={_.isEqual(initStepJsonVal, stepJsonVal)}
                  >
                    Revert all edits
                  </Button>
                  <Button disabled={disableSubmit()} variant="primary" onClick={onSubmitUpdate}>
                    Submit for approval
                  </Button>
                </SpaceBetween>
              }
            >
              {searchParams.get(RULESET_SEARCH_PARAM) || ''}
            </Header>
          }
        >
          <Tabs
            tabs={[
              {
                label: 'Diagram',
                id: 'diagram',
                content: <LayoutFlow setParameterEditor={setParameterEditor} />,
              },
              {
                label: 'Code',
                id: 'code',
                content: <JsonCodeEditor setParameterEditor={setParameterEditor} />,
              },
            ]}
            activeTabId={tab}
            onChange={onTabChanged}
          />
        </Container>
        <Box margin={{ top: 'xxxl' }} padding={{ top: 'xxxl' }}>
          <SpaceBetween size="xxl">
            <div />
            <div />
            {parameterEditor}
          </SpaceBetween>
        </Box>
      </Grid>
    </Box>
  )
}
