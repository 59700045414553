import { config } from '../components/RulesetTool'
import axios from 'axios'

export async function GetAllTreatmentNamesByClientId(props) {
  const response = await axios.request({
    method: 'POST',
    url: config.safesEndpoint,
    data: {
      ops: 'GetAllTreatmentNamesByClientId',
      body: `{ 
        "userAlias": "${props.userAlias}",
        "clientId": "${props.clientId}"
      }`,
    },
    headers: {
      'content-type': 'application/json',
    },
  })

  return response.data
}

export async function GetTreatment(props) {
  const response = await axios.request({
    method: 'POST',
    url: config.safesEndpoint,
    data: {
      ops: 'GetTreatment',
      body: `{ 
        "userAlias": "${props.userAlias}",
        "clientId": "${props.clientId}",
        "treatmentName": "${props.treatmentName}"
      }`,
    },
    headers: {
      'content-type': 'application/json',
    },
  })
  return response.data
}

export async function UpsertTreatment(props) {
  console.log('in upsert treatment')
  const response = await axios.request({
    method: 'POST',
    url: config.safesEndpoint,
    data: {
      ops: 'UpsertTreatment',
      body: `{ 
        "userAlias": "${props.userAlias}",
        "clientId": "${props.clientId}",
        "treatmentName": "${props.treatmentName}",
        "firstStepName": "${props.firstStepName}",
        "steps": ${props.steps}
      }`,
    },
    headers: {
      'content-type': 'application/json',
    },
  })
  return response.data
}

export async function DeactivateTreatment(props) {
  const response = await axios.request({
    method: 'POST',
    url: config.safesEndpoint,
    data: {
      ops: 'DeactivateTreatment',
      body: `{ 
        "userAlias": "${props.userAlias}",
        "clientId": "${props.clientId}",
        "treatmentName": "${props.treatmentName}"
      }`,
    },
    headers: {
      'content-type': 'application/json',
    },
  })
  return response.data
}
