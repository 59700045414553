// Promise polyfill
import 'core-js/features/promise'

import { render } from 'react-dom'
import { HashRouter } from 'react-router-dom'
import App from './components/App'
import '@amzn/awsui-global-styles/polaris.css'
import { getMidwayJwtToken } from './auth/MidwayJwtToken'
import axios from 'axios'
import { initializeAppSettings } from './config/AppSettings'
import React from 'react'
import jwtDecode, { JwtPayload } from 'jwt-decode'
import { persistor, store } from './store/store'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'

// Add Authorization header to all axios calls
axios.interceptors.request.use(async (config) => {
  const jwtToken = await getMidwayJwtToken()
  config.headers.Authorization = `Bearer ${jwtToken}`
  return config
})
;(async () => {
  // Make sure Midway is present before rendering
  const jwtToken = await getMidwayJwtToken()
  //10lines

  const decodedToken: JwtPayload = jwtDecode(jwtToken)
  // let obj = JSON.parse(decodedToken)
  console.log(decodedToken.sub)

  // Initialize application settings
  const appSettings = (await axios('/settings.json')).data
  initializeAppSettings(appSettings)

  render(
    <HashRouter>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <App userAlias={decodedToken.sub} />
        </PersistGate>
      </Provider>
    </HashRouter>,
    document.querySelector('#app')
  )
})()
