import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Edge, Node } from 'react-flow-renderer'

export const ruleNameUnselected = 'Select a rule'

export interface FraudCheckStep {
  ruleName: string
  onSuccess: string
  onFailure: string
  parameters: object
}

export interface SelectStruct {
  label: string
  value: string
}

export interface LayoutFlowState {
  nodes: Node[]
  initNodes: Node[]
  edges: Edge[]
  initEdges: Edge[]
  ruleParams: object
  initRuleParams: object
  stepJsonVal: FraudCheckStep[]
  initStepJsonVal: FraudCheckStep[]
  clickedSelectedRuleName: string
  curEditRule: [string, boolean]
  curEditRuleShadowMode: [string, boolean]
}

const initialState: LayoutFlowState = {
  nodes: [],
  initNodes: [],
  edges: [],
  initEdges: [],
  ruleParams: {},
  initRuleParams: {},
  stepJsonVal: [],
  initStepJsonVal: [],
  clickedSelectedRuleName: ruleNameUnselected,
  curEditRule: ['', false],
  curEditRuleShadowMode: ['', false],
}

export const layoutFlowSlice = createSlice({
  name: 'layoutFlow',
  initialState,
  reducers: {
    flush: () => {
      return initialState
    },

    revert: (state) => {
      console.log('revert')
      state.nodes = state.initNodes
      state.edges = state.initEdges
      state.ruleParams = state.initRuleParams
      state.stepJsonVal = state.initStepJsonVal
    },

    resetNodeStyle: (state) => {
      state.nodes.map((treeNode) => {
        if (treeNode.id.includes('$SUCCESS')) {
          treeNode.style = {
            ...treeNode.style,
            backgroundColor: '#DCF3A0',
            border: '1px solid #98C91E',
          }
        } else if (treeNode.id.includes('$FAILURE')) {
          treeNode.style = {
            ...treeNode.style,
            backgroundColor: '#F9D5E2',
            border: '1px solid #AD0A30',
          }
        } else {
          treeNode.style = {
            ...treeNode.style,
            backgroundColor: '#FFFFFF',
            border: '1px solid #6C7778',
          }
        }
        treeNode.selected = false
        return treeNode
      })
    },

    setSelectedNodeStyle: (state, action: PayloadAction<Node>) => {
      state.nodes.map((treeNode) => {
        if (treeNode.id === action.payload.id) {
          treeNode.style = {
            ...treeNode.style,
            backgroundColor: '#F9F5FF',
            border: '2px solid #7618FF',
          }
        }
        return treeNode
      })
    },

    setNodes: (state, action: PayloadAction<Node[]>) => {
      state.nodes = action.payload
    },
    setEdges: (state, action: PayloadAction<Edge[]>) => {
      state.edges = action.payload
    },
    setInitNodes: (state, action: PayloadAction<Node[]>) => {
      state.initNodes = action.payload
    },
    setInitEdges: (state, action: PayloadAction<Edge[]>) => {
      state.initEdges = action.payload
    },
    setStepJsonVal: (state, action: PayloadAction<FraudCheckStep[]>) => {
      state.stepJsonVal = action.payload
    },
    setInitStepJsonVal: (state, action: PayloadAction<FraudCheckStep[]>) => {
      state.initStepJsonVal = action.payload
    },
    setRuleParams: (state, action: PayloadAction<object>) => {
      state.ruleParams = action.payload
    },
    setInitRuleParams: (state, action: PayloadAction<object>) => {
      state.initRuleParams = action.payload
    },
    setClickedSelectedRuleName: (state, action: PayloadAction<string>) => {
      state.clickedSelectedRuleName = action.payload
    },
    setCurEditRule: (state, action: PayloadAction<[string, boolean]>) => {
      state.curEditRule = action.payload
    },
    setCurEditRuleShadowMode: (state, action: PayloadAction<[string, boolean]>) => {
      state.curEditRuleShadowMode = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  flush,
  revert,
  setEdges,
  setInitEdges,
  setInitNodes,
  setInitStepJsonVal,
  setNodes,
  setStepJsonVal,
  setRuleParams,
  setSelectedNodeStyle,
  resetNodeStyle,
  setInitRuleParams,
  setClickedSelectedRuleName,
  setCurEditRule,
  setCurEditRuleShadowMode,
} = layoutFlowSlice.actions

export default layoutFlowSlice.reducer

export const convertObjectToParams = (obj: object): Map<string, Map<string, string[]>> => {
  const map = new Map<string, Map<string, string[]>>()
  for (const key in obj) {
    const subMap = new Map<string, string[]>()
    for (const subKey in obj[key]) {
      subMap.set(subKey, obj[key][subKey])
    }
    map.set(key, subMap)
  }
  return map
}

export const convertParamsToObject = (map: Map<string, Map<string, string[]>>): object => {
  const obj = {}
  for (const entry of [...map]) {
    const subObj = {}
    const [rule, subMap] = entry
    for (const item of [...subMap]) {
      const [key, value] = item
      subObj[key] = value
    }
    obj[rule] = subObj
  }
  return obj
}
