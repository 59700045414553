import { initRulesetTool } from '../components/RulesetTool'
import { Config } from '../config/Config'
import { ProdConfig } from '../config/Prod'
import { BetaConfig } from '../config/Beta'
import { getStage } from  '../util/Stage'
import { Stage } from '../common/models/stage'

const StageToConfig: Record<Stage, () => Config> = {
  [Stage.BETA]: () => new BetaConfig(),
  [Stage.PRODUCTION]: () => new ProdConfig(),
};

const stage = getStage();
const config = StageToConfig[stage];

initRulesetTool(config());