import React, { useEffect, useState } from 'react'
import { Alert, Box, CodeEditor, SpaceBetween } from '@amzn/awsui-components-react'
import { i18nStrings } from './JsonCodeEditorParameter'
import Button from '@amzn/awsui-components-react/polaris/button'
import {
  convertFraudCheckStepMapToEdges,
  convertFraudCheckStepMapToNodes,
  convertStepsToNodes,
  fetchRuleParams,
  validateTree,
} from './TreeUtil'
import PropTypes from 'prop-types'
import {
  convertObjectToParams,
  convertParamsToObject,
  setEdges,
  setNodes,
  setRuleParams,
  setStepJsonVal,
} from '../../store/slice/LayoutFlowSlice'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../store/store'

JsonCodeEditor.propTypes = {
  setParameterEditor: PropTypes.func,
}

export default function JsonCodeEditor({ setParameterEditor }) {
  const [loading, setLoading] = useState<boolean>(false)
  const [aceInstance, setAceInstance] = useState<never>()
  const stepJsonVal = useSelector((state: RootState) => state.layoutFlow.stepJsonVal)
  const [displayValidateErrorMessage, setDisplayValidateErrorMessage] = useState<[boolean, string]>(
    [false, '']
  )
  const dispatch = useDispatch()

  useEffect(() => {
    setLoading(true)

    import('ace-builds')
      .then((instance) =>
        import('ace-builds/webpack-resolver')
          .then(() => {
            instance.config.set('useStrictCSP', true)
            instance.config.set('loadWorkerFromBlob', false)
            setLoading(false)
            setAceInstance(instance as never)
          })
          .catch(() => setLoading(false))
      )
      .catch(() => setLoading(false))
  }, [])

  useEffect(() => {
    const ruleParams = fetchRuleParams(stepJsonVal)
    const ruleParamsJson = convertParamsToObject(ruleParams)
    const [isTreeValid, updatedFirstStep, updatedSteps, errorMsg] = validateTree(
      stepJsonVal,
      ruleParams,
      ruleParamsJson
    )
    if (isTreeValid) {
      dispatch(setStepJsonVal(updatedSteps))
    }
  }, [])

  function onDelayedChange(codeVal) {
    let parsed_steps
    try {
      parsed_steps = JSON.parse(codeVal)
    } catch (e) {
      setDisplayValidateErrorMessage([true, `Address JSON error(s) indicated by editor`])
      return
    }
    const ruleParams = fetchRuleParams(parsed_steps)
    const ruleParamsJson = convertParamsToObject(ruleParams)
    const [isTreeValid, updatedFirstStep, updatedSteps, errorMsg] = validateTree(
      parsed_steps,
      ruleParams,
      ruleParamsJson
    )
    if (isTreeValid) {
      setDisplayValidateErrorMessage([false, errorMsg])
      dispatch(setStepJsonVal(parsed_steps))
      dispatch(setRuleParams(ruleParamsJson))
    } else {
      setDisplayValidateErrorMessage([true, errorMsg])
      dispatch(setStepJsonVal(parsed_steps))
      dispatch(setRuleParams(ruleParamsJson))
    }
  }

  useEffect(() => {
    setParameterEditor(<div />)
  }, [])

  return (
    <SpaceBetween size="m">
      <CodeEditor
        onChange={() => {
          // no-op
        }}
        onDelayedChange={(e) => onDelayedChange(e.detail.value)}
        onPreferencesChange={() => {
          // no-op
        }}
        ace={aceInstance}
        value={JSON.stringify(stepJsonVal, null, 2)}
        language="json"
        loading={loading}
        i18nStrings={i18nStrings}
      />
      {displayValidateErrorMessage[0] && (
        <Alert
          onDismiss={() => setDisplayValidateErrorMessage([false, ''])}
          dismissible
          type="error"
          dismissAriaLabel="Close alert"
          header="Validation error"
        >
          {displayValidateErrorMessage[1]}
        </Alert>
      )}
    </SpaceBetween>
  )
}
