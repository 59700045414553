import { Config } from '../config/Config'

export let rulesetTool: RulesetTool;
export let config: typeof rulesetTool.config;

/**
 * Setup RulesetTool
 */
export function initRulesetTool(configuration: Config) {
  rulesetTool = new RulesetTool(configuration);
  config = rulesetTool.config;
}

/**
 * The RulesetTool singleton manages RulesetTool's global state.
 */
export class RulesetTool {
  public config: Config;

  constructor(configuration: Config) {
    this.config = configuration;
  }
}