/**
 * The Config class holds all of RulesetTool's configuration.
 *
 * Please see `BetaConfig` and `ProdConfig` for environment
 * specific configuration overrides.
 *
 * Anything that is always environment specific must be marked abstract.
 */
export abstract class Config {
  /** Environment specific stage type. */
  public abstract stage: string

  /** SAFES client endpoint **/
  public abstract safesEndpoint: string

  /** BRASS client endpoint **/
  public abstract brassEndpoint: string

  /** Approval client endpoint **/
  public abstract approvalEndpoint: string
}
