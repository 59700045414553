import { FraudCheckStep } from '../store/slice/LayoutFlowSlice'
import { failureAllCap, successAllCap } from '../components/tree/TreeUtil'

interface Edge {
  source: string
  target: string
}

const getRoots = (steps: FraudCheckStep[]): FraudCheckStep[] => {
  const roots: FraudCheckStep[] = []
  for (const step of steps) {
    if (!steps.some((s) => s.onFailure === step.ruleName || s.onSuccess === step.ruleName)) {
      // no node leads to this step, so node is root
      roots.push(step)
    }
  }
  return roots
}

const convertStepsToEdges = (steps: FraudCheckStep[]): Edge[] => {
  const edges: Edge[] = []
  for (const step of steps) {
    if (
      step.onSuccess.toUpperCase() !== successAllCap &&
      step.onSuccess.toUpperCase() !== failureAllCap
    ) {
      edges.push({ source: step.ruleName, target: step.onSuccess })
    }

    if (
      step.onFailure.toUpperCase() !== failureAllCap &&
      step.onFailure.toUpperCase() !== successAllCap &&
      step.onFailure !== step.onSuccess
    ) {
      edges.push({ source: step.ruleName, target: step.onFailure })
    }
  }

  return edges
}

const topSort = (
  steps: FraudCheckStep[],
  root: FraudCheckStep
): [boolean, FraudCheckStep[], string] => {
  const edges = convertStepsToEdges(steps)

  const sorted: FraudCheckStep[] = []
  const rootNodes: FraudCheckStep[] = [steps[steps.findIndex((n) => n.ruleName === root.ruleName)]]

  while (rootNodes.length > 0) {
    const n = rootNodes[0]
    rootNodes.splice(0, 1)
    sorted.push(n)

    const edgesFromN = edges.filter((e) => e.source === n.ruleName)
    const nodesWithEdgeFromN: FraudCheckStep[] = steps.filter((n) =>
      edgesFromN.some((e) => e.target === n.ruleName)
    )
    for (const node of nodesWithEdgeFromN) {
      for (const toRemove of edgesFromN.filter((e) => e.target === node.ruleName)) {
        edges.splice(edges.indexOf(toRemove), 1)
      }
      if (!edges.some((e) => e.target === node.ruleName)) {
        rootNodes.push(node)
      }
    }
  }

  if (edges.length > 0) {
    return [
      false,
      [],
      `Could not create valid tree from nodes. Assert no loops exist where rule can lead to itself. Assert all onSuccess/onFailure values point to existing nodes.`,
    ]
  }

  return [true, sorted, '']
}

export const validateSortTree = (steps: FraudCheckStep[]): [boolean, FraudCheckStep[], string] => {
  const roots = getRoots(steps)
  if (roots.length !== 1) {
    if (roots.length === 0) return [false, [], 'No valid first step found']
    else
      return [false, [], `Invalid number of first step ${roots.map((s) => s.ruleName).join(', ')}`]
  }

  return topSort(steps, roots[0])
}
