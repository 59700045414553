import { Navigate, Route, Routes } from 'react-router-dom'
import * as React from 'react'
import TreePage from './tree/TreePage'
import PropTypes from 'prop-types'
import RequestPage from './request/RequestPage'
import TopNavigation from './topNavigation/TopNavigation'

App.propTypes = {
  userAlias: PropTypes.string,
}

export default function App(props) {
  return (
    <div>
      <Routes>
        <Route
          path="/ruleset/:tab"
          element={
            <div>
              <TopNavigation userAlias={props.userAlias} />
              <TreePage />
            </div>
          }
        />
        <Route path="/request/:id" element={<RequestPage />} />
        <Route path="*" element={<Navigate to="/ruleset/diagram" />} />
      </Routes>
    </div>
  )
}
