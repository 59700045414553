import { FraudChecks } from './FraudChecks'

export const BOOLEAN = 'boolean'
export const NUMBER = 'number'
export const NUMBER_LIST = 'numList'
export const NON_NEGATIVE_INTEGER = 'nonNegInt'
export const NON_NEGATIVE_INTEGER_LIST = 'nonNegIntList'

export const FraudCheckParameterTypes = new Map<string, Map<string, string>>([
  [
    FraudChecks.BitsCheck,
    new Map<string, string>([['maxPurchaseDaysLimit', NON_NEGATIVE_INTEGER]]),
  ],
  [
    FraudChecks.ChargeStatusCheck,
    new Map<string, string>([['allowedProcessorStatusCodes', NON_NEGATIVE_INTEGER_LIST]]),
  ],
  [
    FraudChecks.DigitalOrdersCheck,
    new Map<string, string>([
      ['maxPurchaseDaysLimit', NON_NEGATIVE_INTEGER],
      ['minPurchaseDaysLimit', NON_NEGATIVE_INTEGER],
    ]),
  ],
  [
    FraudChecks.HighVelocityIssuingBankCheck,
    new Map<string, string>([['highVelocityBlockingLayers', NON_NEGATIVE_INTEGER_LIST]]),
  ],
  [
    FraudChecks.MobileTokenLimitCheck,
    new Map<string, string>([['maxTokenLimit', NON_NEGATIVE_INTEGER]]),
  ],
  [
    FraudChecks.PhysicalOrdersCheck,
    new Map<string, string>([
      ['minPurchaseOrders', NON_NEGATIVE_INTEGER],
      ['maxPurchaseDaysLimit', NON_NEGATIVE_INTEGER],
      ['minPurchaseDaysLimit', NON_NEGATIVE_INTEGER],
    ]),
  ],
  [FraudChecks.TokenLimitCheck, new Map<string, string>([['maxTokenLimit', NON_NEGATIVE_INTEGER]])],
  [
    FraudChecks.TwoFactorAuthenticationCheck,
    new Map<string, string>([
      ['enforceLocalNumbersForEuMfa', BOOLEAN],
      ['enforceLocalNumbersForBancontact', BOOLEAN],
    ]),
  ],
])
