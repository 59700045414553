import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import Select from '@amzn/awsui-components-react/polaris/select'
import { GetAllTreatmentNamesByClientId } from '../../apis/SAFESApi'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../store/store'
import './styles.scss'
import logo from '../../assets/BrandGG.png'
import {
  ruleNameUnselected,
  SelectStruct,
  setClickedSelectedRuleName,
  setEdges,
  setInitEdges,
  setInitNodes,
  setInitStepJsonVal,
  setNodes,
  setStepJsonVal,
} from '../../store/slice/LayoutFlowSlice'
import { setUserAlias } from '../../store/slice/UserInfoSlice'
import { GetClientIds } from '../../apis/BRASSApi'
import PropTypes from 'prop-types'
import { ColumnLayout } from '@amzn/awsui-components-react'

TopNavigation.propTypes = {
  userAlias: PropTypes.string,
}

export const CLIENT_SEARCH_PARAM = 'client'
export const RULESET_SEARCH_PARAM = 'ruleset'

export default function TopNavigation(props) {
  const userAlias = useSelector((state: RootState) => state.userInfo.userAlias)
  const [allClientIds, setAllClientIds] = useState<string[]>([])
  const [allClientIdOptions, setAllClientIdOptions] = useState<SelectStruct[]>([])
  const [allTreatmentNames, setAllTreatmentNames] = useState<string[]>([])
  const [allTreatmentNameOptions, setAllTreatmentNameOptions] = useState<SelectStruct[]>([])
  const [selectedClientIdOption, setSelectedClientIdOption] = useState<SelectStruct | null>(null)
  const [selectedTreatmentNameOption, setSelectedTreatmentNameOption] =
    useState<SelectStruct | null>(null)
  const [searchParams, setSearchParams] = useSearchParams()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setUserAlias(props.userAlias))
  }, [])

  const fetchClientIds = async () => {
    const response = await GetClientIds({ userAlias })
    setAllClientIds(response)
  }

  useEffect(() => {
    fetchClientIds()
  }, [userAlias])

  useEffect(() => {
    const options: SelectStruct[] = allClientIds.map((clientId) => {
      return { label: clientId, value: clientId }
    })
    setAllClientIdOptions(options)
  }, [allClientIds])

  useEffect(() => {
    if (searchParams.get(CLIENT_SEARCH_PARAM)) {
      setSelectedClientIdOption({
        label: searchParams.get(CLIENT_SEARCH_PARAM) || '',
        value: searchParams.get(CLIENT_SEARCH_PARAM) || '',
      })
    } else {
      setSelectedClientIdOption(null)
    }
  }, [searchParams])

  useEffect(() => {
    if (
      !searchParams.get(CLIENT_SEARCH_PARAM) &&
      allClientIdOptions.length &&
      (!selectedTreatmentNameOption ||
        allTreatmentNameOptions.indexOf(selectedTreatmentNameOption) === -1)
    ) {
      setSelectedClientIdOption(allClientIdOptions[0])
      searchParams.set(CLIENT_SEARCH_PARAM, allClientIdOptions[0].value)
      setSearchParams(searchParams)
    }
  }, [allClientIdOptions])

  const fetchTreatments = async () => {
    const response = await GetAllTreatmentNamesByClientId({
      userAlias,
      clientId: searchParams.get(CLIENT_SEARCH_PARAM) || '',
    })
    if (response.indexOf(searchParams.get(RULESET_SEARCH_PARAM)) === -1) {
      searchParams.delete(RULESET_SEARCH_PARAM)
      setSearchParams(searchParams)
      setAllTreatmentNameOptions([])
      setSelectedTreatmentNameOption(null)
    }
    setAllTreatmentNames(response)
  }

  useEffect(() => {
    if (
      searchParams.get(CLIENT_SEARCH_PARAM) !== undefined &&
      searchParams.get(CLIENT_SEARCH_PARAM) !== null
    ) {
      fetchTreatments()
    }
  }, [searchParams])

  useEffect(() => {
    const options: SelectStruct[] = allTreatmentNames.sort().map((t) => {
      return { label: t, value: t }
    })
    setAllTreatmentNameOptions(options)
  }, [allTreatmentNames])

  useEffect(() => {
    if (searchParams.get(RULESET_SEARCH_PARAM)) {
      setSelectedTreatmentNameOption({
        label: searchParams.get(RULESET_SEARCH_PARAM) || '',
        value: searchParams.get(RULESET_SEARCH_PARAM) || '',
      })
    } else {
      setSelectedTreatmentNameOption(null)
    }
  }, [searchParams])

  useEffect(() => {
    if (
      !searchParams.get(RULESET_SEARCH_PARAM) &&
      allTreatmentNameOptions.length &&
      (!selectedTreatmentNameOption ||
        allTreatmentNameOptions.indexOf(selectedTreatmentNameOption) === -1)
    ) {
      setSelectedTreatmentNameOption(allTreatmentNameOptions[0])
      searchParams.set(RULESET_SEARCH_PARAM, allTreatmentNameOptions[0].value)
      setSearchParams(searchParams)
    }
  }, [allTreatmentNameOptions])

  return (
    <div className="topNav">
      <img src={logo} className="logo" />
      <Select
        className="treatmentSelect"
        selectedOption={selectedTreatmentNameOption}
        onChange={({ detail }) => {
          setSelectedTreatmentNameOption({
            label: detail.selectedOption.label!,
            value: detail.selectedOption.value!,
          })
          if (detail.selectedOption.value !== searchParams.get(RULESET_SEARCH_PARAM)) {
            searchParams.set(RULESET_SEARCH_PARAM, detail.selectedOption.value!)
            setSearchParams(searchParams)
            dispatch(setClickedSelectedRuleName(ruleNameUnselected))
            dispatch(setEdges([]))
            dispatch(setNodes([]))
            dispatch(setInitNodes([]))
            dispatch(setInitEdges([]))
            dispatch(setInitStepJsonVal([]))
            dispatch(setStepJsonVal([]))
          }
        }}
        empty="No rulesets"
        loadingText="Loading rulesets"
        placeholder="Choose a ruleset"
        options={allTreatmentNameOptions}
        selectedAriaLabel="Selected"
      />

      <div className="userInfo">
        <ColumnLayout columns={1}>
          <p className="userAlias"> Hello {userAlias}</p>
          <Select
            className="clientSelect"
            selectedOption={selectedClientIdOption}
            onChange={({ detail }) => {
              setSelectedClientIdOption({
                label: detail.selectedOption.label!,
                value: detail.selectedOption.value!,
              })
              setSelectedTreatmentNameOption(null)
              if (detail.selectedOption.value !== searchParams.get(CLIENT_SEARCH_PARAM)) {
                searchParams.set(CLIENT_SEARCH_PARAM, detail.selectedOption.value!)
                setSearchParams(searchParams)
                dispatch(setClickedSelectedRuleName(ruleNameUnselected))
                dispatch(setEdges([]))
                dispatch(setNodes([]))
                dispatch(setInitNodes([]))
                dispatch(setInitEdges([]))
                dispatch(setInitStepJsonVal([]))
                dispatch(setStepJsonVal([]))
              }
            }}
            empty="No clients"
            placeholder="Choose a client"
            options={allClientIdOptions}
            selectedAriaLabel="Selected"
          />
        </ColumnLayout>
      </div>
    </div>
  )
}
