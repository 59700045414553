import { FraudCheckStep } from '../store/slice/LayoutFlowSlice'
import {
  BOOLEAN,
  FraudCheckParameterTypes,
  NON_NEGATIVE_INTEGER,
  NON_NEGATIVE_INTEGER_LIST,
  NUMBER,
  NUMBER_LIST,
} from './FraudCheckParameterTypes'

function validateByType(type: string, input: string[]) {
  switch (type) {
    case BOOLEAN: {
      return input.length === 1 && validateBoolean(input[0])
    }
    case NUMBER: {
      return input.length === 1 && validateNumber(input[0])
    }
    case NUMBER_LIST: {
      return validateNumList(input)
    }
    case NON_NEGATIVE_INTEGER: {
      return input.length === 1 && validateNonNegativeInteger(input[0])
    }
    case NON_NEGATIVE_INTEGER_LIST: {
      return validateNonNegIntList(input)
    }
    default: {
      return true
    }
  }
}

function validateNumber(parameter) {
  return !isNaN(Number(parameter))
}

function validateBoolean(parameter) {
  return parameter.toLowerCase() === 'true' || parameter.toLowerCase() === 'false'
}

function validateNumList(parameter) {
  for (const num of parameter) {
    if (!validateNumber(num)) return false
  }
  return true
}

function validateNonNegativeInteger(parameter) {
  if (parameter.includes('.')) return false
  const num = Number(parameter)
  return !isNaN(num) && num >= 0 && Number.isInteger(num)
}

function validateNonNegIntList(parameter) {
  for (const num of parameter) {
    if (!validateNonNegativeInteger(num)) return false
  }
  return true
}

export function getValidParameters(
  ruleParamsJson,
  ruleParams,
  values
): [boolean, FraudCheckStep[], string] {
  const invalidParam: string[] = []
  const ret: FraudCheckStep[] = []
  for (const originalRule of values) {
    const rule = { ...originalRule }
    if (!(rule.ruleName.includes('Success') || rule.ruleName.includes('Failure'))) {
      if (rule.onFailure.includes('Failure')) rule.onFailure = 'FAILURE'
      if (rule.onFailure.includes('Success')) rule.onFailure = 'SUCCESS'
      if (rule.onSuccess.includes('Success')) rule.onSuccess = 'SUCCESS'
      if (rule.onSuccess.includes('Failure')) rule.onSuccess = 'FAILURE'
      if (ruleParams.has(rule.ruleName)) {
        const paramsObj = { ...ruleParamsJson[rule.ruleName] }
        for (const param of Object.keys(paramsObj)) {
          if (
            FraudCheckParameterTypes.has(rule.ruleName) &&
            FraudCheckParameterTypes.get(rule.ruleName)!.has(param)
          ) {
            const type = FraudCheckParameterTypes.get(rule.ruleName)!.get(param)
            if (!validateByType(type!, paramsObj[param])) {
              invalidParam.push(param + ' = ' + paramsObj[param] + ' in ' + rule.ruleName)
            }
          }

          /* Clean up empty params to avoid json format error */
          if (paramsObj[param].length === 1 && paramsObj[param][0]!.trim().length === 0) {
            paramsObj[param] = []
          }
        }
        rule.parameters = paramsObj
      }
      ret.push(rule)
    }
  }
  if (invalidParam.length === 0) return [true, ret, '']
  return [false, [], 'Invalid parameter: ' + invalidParam.join('; ') + '. ']
}
