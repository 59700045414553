export enum FraudChecks {
  AccountStatusCheck = 'AccountStatusCheck',
  ActiveSubscriptionCheck = 'ActiveSubscriptionCheck',
  BancontactCheck = 'BancontactCheck',
  BitsCheck = 'BitsCheck',
  ChargeCompletedCheck = 'ChargeCompletedCheck',
  ChargeStatusCheck = 'ChargeStatusCheck',
  DigitalOrdersCheck = 'DigitalOrdersCheck',
  DigitalWalletCheck = 'DigitalWalletCheck',
  DirectDebitCheck = 'DirectDebitCheck',
  EmandateCheck = 'EmandateCheck',
  EUMultiFactorAuthenticationCheck = 'EUMultiFactorAuthenticationCheck',
  ExternallyManagedPaymentCheck = 'ExternallyManagedPaymentCheck',
  FreeTrialAccountCheck = 'FreeTrialAccountCheck',
  FuseCheck = 'FuseCheck',
  GiftCardCheck = 'GiftCardCheck',
  GiftedPrimeCheck = 'GiftedPrimeCheck',
  HighVelocityIssuingBankCheck = 'HighVelocityIssuingBankCheck',
  IdealCheck = 'IdealCheck',
  IssuingBankCheck = 'IssuingBankCheck',
  MobileCarrierBillingCheck = 'MobileCarrierBillingCheck',
  MobilePhoneCheck = 'MobilePhoneCheck',
  MobileTokenLimitCheck = 'MobileTokenLimitCheck',
  PaidTenureCheck = 'PaidTenureCheck',
  PhysicalOrdersCheck = 'PhysicalOrdersCheck',
  PrepaidCreditCardCheck = 'PrepaidCreditCardCheck',
  RequiredPrimeBenefitsCheck = 'RequiredPrimeBenefitsCheck',
  RiskContextCheck = 'RiskContextCheck',
  TokenLimitCheck = 'TokenLimitCheck',
  TwoFactorAuthenticationCheck = 'TwoFactorAuthenticationCheck',
  UnifiedPaymentsInterfaceCheck = 'UnifiedPaymentsInterfaceCheck',
}
