import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface UserInfoState {
  userAlias: string
}

const initialState: UserInfoState = {
  userAlias: '',
}

export const userInfoSlice = createSlice({
  name: 'userInfo',
  initialState,
  reducers: {
    setUserAlias: (state, action: PayloadAction<string>) => {
      state.userAlias = action.payload
    },
  },
})

export const { setUserAlias } = userInfoSlice.actions

export default userInfoSlice.reducer
