import { Edge, MarkerType, Node, Position } from 'react-flow-renderer'
import dagre from 'dagre'
import { FraudCheckStep } from '../../store/slice/LayoutFlowSlice'
import { validateSortTree } from '../../util/RulesetValidator'
import { getValidParameters } from '../../util/ParameterValidator'

const position = { x: 0, y: 0 }
const edgeType = 'smoothstep'

const nodeWidth = 172
const nodeHeight = 50
export const successAllCap = 'SUCCESS'
export const failureAllCap = 'FAILURE'
export const successEdgeDelimiter = '!'
export const failureEdgeDelimiter = '?'
export const leafNodeIdDelimiter = '$'
const failureNodeStyle = {
  backgroundColor: '#F9D5E2',
  border: '1px solid #AD0A30',
  width: 125,
  fontSize: 14,
}
const successNodeStyle = {
  backgroundColor: '#DCF3A0',
  border: '1px solid #98C91E',
  width: 125,
  fontSize: 14,
}

export function getLayoutedElements(nodes: Node[], edges: Edge[], direction: string) {
  const dagreGraph = new dagre.graphlib.Graph()
  dagreGraph.setDefaultEdgeLabel(() => ({}))

  const isHorizontal = direction === 'LR'
  dagreGraph.setGraph({ rankdir: direction })

  nodes.forEach((node) => {
    dagreGraph.setNode(node.id, {
      width: nodeWidth,
      height: nodeHeight,
    })
  })

  edges.forEach((edge) => {
    dagreGraph.setEdge(edge.source, edge.target)
  })

  dagre.layout(dagreGraph)

  try {
    nodes[0].targetPosition = Position.Bottom
  } catch {
    //
  }
  let nodesEdited = false
  const editedNodes: Node[] = []
  nodes.forEach((node) => {
    const nodeWithPosition = dagreGraph.node(node.id)
    const editedNode = {
      ...node,
      targetPosition: isHorizontal ? Position.Left : Position.Top,
      sourcePosition: isHorizontal ? Position.Right : Position.Bottom,
      // We are shifting the dagre node position (anchor=center center) to the top left
      // so it matches the React Flow node anchor point (top left).
      position: {
        x: nodeWithPosition.x - nodeWidth / 2,
        y: nodeWithPosition.y - nodeHeight / 2,
      },
    }
    editedNodes.push(editedNode)
    if (
      editedNode.position.x !== node.position.x ||
      editedNode.position.y !== node.position.y ||
      editedNode.targetPosition !== node.targetPosition ||
      editedNode.sourcePosition !== node.sourcePosition
    )
      nodesEdited = true
  })

  if (nodesEdited) return { nodes: editedNodes, edges }
  else return { nodes, edges }
}

export function convertStepsToNodes(stepList) {
  console.log('convert step to node', stepList)
  const nodes_map = new Map()
  stepList.forEach((step) => {
    const cloned = { ...step }
    cloned.onSuccess =
      step.onSuccess.toUpperCase() === successAllCap ||
      step.onSuccess.toUpperCase() === failureAllCap
        ? step.onSuccess.toUpperCase()
        : step.onSuccess
    cloned.onFailure =
      step.onFailure.toUpperCase() === successAllCap ||
      step.onFailure.toUpperCase() === failureAllCap
        ? step.onFailure.toUpperCase()
        : step.onFailure
    nodes_map.set(cloned.ruleName, cloned as FraudCheckStep)
  })
  return nodes_map
}

/* Split rule name by camel case */
export function getFraudCheckDisplayName(ruleName) {
  return ruleName.replace(/([a-z](?=[A-Z]))/g, '$1 ')
}

export function convertFraudCheckStepMapToNodes(nodeMap) {
  console.log('in convert to init node', nodeMap)
  const new_arr: Node[] = []
  nodeMap.forEach((value: FraudCheckStep) => {
    new_arr.push({
      id: value.ruleName,
      data: { label: getFraudCheckDisplayName(value.ruleName) },
      position,
      style: { border: '1px solid #6C7778', width: 200, fontWeight: 700, fontSize: 14 },
    })
    if (value.onFailure === failureAllCap || value.onSuccess === failureAllCap) {
      new_arr.push(generateFailureLeafNode(value.ruleName))
    }
    if (value.onSuccess === successAllCap || value.onFailure === successAllCap) {
      new_arr.push(generateSuccessLeafNode(value.ruleName))
    }
  })
  console.log('converted init node: ', new_arr)
  return new_arr
}

export function convertFraudCheckStepMapToEdges(nodeMap) {
  console.log('in convert to init edge', nodeMap)
  const init_edges: Edge[] = []
  nodeMap.forEach((value: FraudCheckStep) => {
    const successLeafNode = value.ruleName + leafNodeIdDelimiter + successAllCap
    const failureLeafNode = value.ruleName + leafNodeIdDelimiter + failureAllCap
    let successTarget = value.onSuccess
    if (successTarget === successAllCap) successTarget = successLeafNode
    else if (successTarget === failureAllCap) successTarget = failureLeafNode
    init_edges.push({
      id: value.ruleName + successEdgeDelimiter + value.onSuccess,
      source: value.ruleName,
      target: successTarget,
      type: edgeType,
      style: { stroke: '#98C91E', width: 'auto', whiteSpace: 'nowrap' },
      markerEnd: { type: MarkerType.ArrowClosed, color: '#98C91E' },
    })
    let failureTarget = value.onFailure
    if (failureTarget === successAllCap) failureTarget = successLeafNode
    else if (failureTarget === failureAllCap) failureTarget = failureLeafNode
    init_edges.push({
      id: value.ruleName + failureEdgeDelimiter + value.onFailure,
      source: value.ruleName,
      target: failureTarget,
      type: edgeType,
      style: { stroke: '#AD0A30', width: 'auto', whiteSpace: 'nowrap' },
      markerEnd: { type: MarkerType.ArrowClosed, color: '#AD0A30' },
    })
  })
  console.log('converted init edge: ', init_edges)
  return init_edges
}

export function convertEdgesToFraudCheckStepMap(edges) {
  const nodeMap = new Map()
  console.log('convert init edges to nodes', edges)
  edges.forEach((e) => {
    if (!nodeMap.has(e.source)) {
      nodeMap.set(e.source, {
        ruleName: e.source,
        onSuccess: '',
        onFailure: '',
        parameters: '',
      })
    }
    const step: FraudCheckStep = nodeMap.get(e.source)
    if (e.id.includes(failureEdgeDelimiter)) {
      const [_, result] = e.id.split(failureEdgeDelimiter)
      e.target.includes(leafNodeIdDelimiter)
        ? (step.onFailure = result)
        : (step.onFailure = e.target)
    } else {
      const [_, result] = e.id.split(successEdgeDelimiter)
      e.target.includes(leafNodeIdDelimiter)
        ? (step.onSuccess = result)
        : (step.onSuccess = e.target)
    }
  })
  return nodeMap
}

export function fetchRuleParams(stepList) {
  const param_map = new Map<string, Map<string, string[]>>()
  stepList.map((step) => {
    const rule_param = new Map<string, string[]>()
    Object.keys(step.parameters).map((k) => {
      rule_param.set(k, step.parameters[k])
    })
    param_map.set(step.ruleName, rule_param)
  })
  return param_map
}

export function getChildNodesFromEdge(edges, curNode) {
  let successNodeId = ''
  let failureNodeId = ''
  edges.forEach((edge) => {
    if (edge.id.startsWith(curNode + successEdgeDelimiter)) {
      successNodeId =
        edge.target.includes(successAllCap) || edge.target.includes(failureAllCap)
          ? edge.target
          : successNodeId
      console.log('from success edge, ', successNodeId)
    }
    if (edge.id.startsWith(curNode + failureEdgeDelimiter)) {
      failureNodeId =
        edge.target.includes(successAllCap) || edge.target.includes(failureAllCap)
          ? edge.target
          : failureNodeId
      console.log('from failure edge, ', failureNodeId)
    }
  })
  return [successNodeId, failureNodeId]
}

export function generateSuccessLeafNode(prefix) {
  return {
    id: prefix + leafNodeIdDelimiter + successAllCap,
    data: { label: successAllCap },
    position,
    style: successNodeStyle,
    type: 'output',
  }
}

export function generateFailureLeafNode(prefix) {
  return {
    id: prefix + leafNodeIdDelimiter + failureAllCap,
    data: { label: failureAllCap },
    position,
    style: failureNodeStyle,
    type: 'output',
  }
}
export function validateTree(
  toValidate,
  ruleParams,
  ruleParamsJson
): [boolean, string, FraudCheckStep[], string] {
  const [isTreeValid, sortedSteps, rulesetMessage] = validateSortTree(toValidate)
  const [isParamsValid, fixedSteps, paramMessage] = getValidParameters(
    ruleParamsJson,
    ruleParams,
    sortedSteps
  )
  if (isTreeValid && isParamsValid) {
    return [true, fixedSteps.length > 0 ? fixedSteps[0].ruleName : 'None', fixedSteps, '']
  }
  return [
    false,
    fixedSteps.length > 0 ? fixedSteps[0].ruleName : 'None',
    fixedSteps,
    rulesetMessage + ' ' + paramMessage,
  ]
}
