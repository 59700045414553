import { FraudChecks } from './FraudChecks'

export const FraudCheckDescriptions = new Map<string, string>([
  [
    FraudChecks.AccountStatusCheck,
    'The rule checks for valid or invalid account statuses for customers marked by TRMS.',
  ],
  [
    FraudChecks.ActiveSubscriptionCheck,
    'The rule checks if customer has active Amazon subscription, and determines if customers is paid or free trial.',
  ],
  [
    FraudChecks.ChargeCompletedCheck,
    "The rule checks that the latest payment on their subscription has succeeded or not.",
  ],
  [
    FraudChecks.ChargeStatusCheck,
    "The rule performs an enhanced vet on a customer's preferred credit card to validate its legitimacy.",
  ],
  [
    FraudChecks.HighVelocityIssuingBankCheck,
    "The rule determines if any customer's current active payment methods stored on their wallet is part of an ongoing high velocity issuing bank event."
  ],
  [
    FraudChecks.MobilePhoneCheck,
    'The rule checks if the customer has a current verified and valid mobile number on their Amazon account.',
  ],
  [
    FraudChecks.MobileTokenLimitCheck,
    "The rule checks if verified mobile number associated with customer's Amazon account has not exceeded threshold.",
  ],
  [
    FraudChecks.RequiredPrimeBenefitsCheck,
    'The rule checks if customer has valid or invalid Datapath facets.',
  ],
  [
    FraudChecks.RiskContextCheck,
    'The rule checks if there are any invalid or valid risk contexts defined by RCVS.'
  ],
  [
    FraudChecks.TokenLimitCheck,
    "Checks if payment methods associated with customer's Amazon account has not exceeded threshold.",
  ],
  [
    FraudChecks.TwoFactorAuthenticationCheck,
    'Determines if customer requires to under mobile verification.',
  ],
  [
    FraudChecks.DigitalOrdersCheck,
    'The rule checks if customer has made any previous purchases for digital goods over a specific amount and within a specific time window.',
  ],
  [
    FraudChecks.EUMultiFactorAuthenticationCheck,
    'The rule checks if a customer has RecurringPaymentSources, indicating they are subject to EU multi-factor authentication.',
  ],
  [
    FraudChecks.ExternallyManagedPaymentCheck,
    "The rule checks if a customer's subscription was paid through an externally managed payment method (specifically Google or Apple Pay),"
  ],
  [
    FraudChecks.FuseCheck,
    "The rule checks if a customer's subscription belongs to a Fuse program.",
  ],
  [
    FraudChecks.GiftCardCheck,
    'The rule checks if the customer is using gift cards to pay for their Prime subscription.',
  ],
  [
    FraudChecks.GiftedPrimeCheck, 
    'The rule checks if the current Prime subscription is gifted.'
  ],
  [
    FraudChecks.MobileCarrierBillingCheck,
    'The rule checks if the customer manages their subscription through a mobile carrier billing method.',
  ],
  [
    FraudChecks.PaidTenureCheck,
    "The rule checks if the customer's subscription has a valid paid tenure. ",
  ],
  [
    FraudChecks.PhysicalOrdersCheck,
    'The rule checks if customer has made any previous purchases for physical goods over a specific amount and within a specific time window.',
  ],
  [
    FraudChecks.DirectDebitCheck,
    'The rule checks if the customer is paying for their Amazon subscription using a bank account in eligible countries (DE, FR, IT, ES)',
  ],
  [
    FraudChecks.IdealCheck,
    'The rule checks if the customer is paying for their Amazon subscription with an iDEAL payment method (NL only)',
  ],
  [
    FraudChecks.PrepaidCreditCardCheck,
    "The rule checks if customer has valid or invalid prepaid credit cards on their Amazon wallet.",
  ],
  [
    FraudChecks.BancontactCheck,
    'The rule checks if the customer is paying for their Amazon subscription with a Bancontact payment method (BE only).',
  ],
  [
    FraudChecks.UnifiedPaymentsInterfaceCheck,
    'The rule checks if the customer is paying for their Amazon subscription with a UPI payment method (IN only).',
  ],
  [
    FraudChecks.EmandateCheck,
    'The rule checks if the customer has "EMANDATE_AGREED" tag in their Prime subscription (IN only).',
  ],
  [
    FraudChecks.DigitalWalletCheck,
    "The rule checks if the customer is paying for their Prime Video subscription with a digital wallet payment method (ROE-EU & ROW-FE only).",
  ],
])
