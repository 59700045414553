import { config } from '../components/RulesetTool'
import axios from 'axios'

export async function GetClientIds(props) {
  const response = await axios.request({
    method: 'POST',
    url: config.brassEndpoint,
    data: {
      ops: 'GetClientIds',
      body: `{
        "userAlias": "${props.userAlias}"
      }`,
    },
    headers: {
      'content-type': 'application/json',
    },
  })

  return response.data
}

export async function IsAuthorized(props) {
  const response = await axios.request({
    method: 'POST',
    url: config.brassEndpoint,
    data: {
      ops: 'IsAuthorized',
      body: `{
        "userAlias": "${props.userAlias}",
        "clientId": "${props.clientId}",
        "operation": "${props.operation}"
      }`,
    },
    headers: {
      'content-type': 'application/json',
    },
  })

  return response.data
}
