import ReactFlow, {
  applyEdgeChanges,
  applyNodeChanges,
  Edge,
  EdgeChange,
  MarkerType,
  Node,
  NodeChange,
} from 'react-flow-renderer'
import Button from '@amzn/awsui-components-react/polaris/button'
import {
  convertObjectToParams,
  convertParamsToObject,
  FraudCheckStep,
  resetNodeStyle,
  ruleNameUnselected,
  SelectStruct,
  setClickedSelectedRuleName,
  setCurEditRule,
  setCurEditRuleShadowMode,
  setEdges,
  setNodes,
  setRuleParams,
  setSelectedNodeStyle,
  setStepJsonVal,
} from '../../store/slice/LayoutFlowSlice'
import { Alert, Header, Input, Select, SpaceBetween } from '@amzn/awsui-components-react'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../store/store'
import { FraudChecks } from '../../util/FraudChecks'
import {
  convertEdgesToFraudCheckStepMap,
  failureAllCap,
  failureEdgeDelimiter,
  generateFailureLeafNode,
  generateSuccessLeafNode,
  getChildNodesFromEdge,
  getFraudCheckDisplayName,
  getLayoutedElements,
  leafNodeIdDelimiter,
  successAllCap,
  successEdgeDelimiter,
} from './TreeUtil'
import { validateSortTree } from '../../util/RulesetValidator'
import PropTypes from 'prop-types'
import { getValidParameters } from '../../util/ParameterValidator'
import { FraudCheckDescriptions } from '../../util/FraudCheckDescriptions'
import {
  FraudCheckRuleDetailsFailure,
  FraudCheckRuleDetailsSuccess,
} from '../../util/FraudCheckRuleDetails'
import passReason from '../../assets/PassReason.png'
import failReason from '../../assets/FailReason.png'

const position = { x: 0, y: 0 }
const edgeType = 'smoothstep'
const suffixLength = 7

LayoutFlow.propTypes = {
  setParameterEditor: PropTypes.func,
}

export default function LayoutFlow({ setParameterEditor }) {
  const initNodes = useSelector((state: RootState) => state.layoutFlow.initNodes)
  const initEdges = useSelector((state: RootState) => state.layoutFlow.initEdges)
  const ruleParamsJson = useSelector((state: RootState) => state.layoutFlow.ruleParams)
  const stepJsonVal = useSelector((state: RootState) => state.layoutFlow.stepJsonVal)
  const curEditRule = useSelector((state: RootState) => state.layoutFlow.curEditRule)
  const curEditRuleShadowMode = useSelector(
    (state: RootState) => state.layoutFlow.curEditRuleShadowMode
  )
  const ruleParams = convertObjectToParams(ruleParamsJson)
  const { nodes, edges } = useSelector((state: RootState) =>
    getLayoutedElements(state.layoutFlow.nodes, state.layoutFlow.edges, 'TB')
  )
  const clickedSelectedRuleName = useSelector(
    (state: RootState) => state.layoutFlow.clickedSelectedRuleName
  )
  const [allRuleSuccessOptions, setAllRuleSuccessOptions] = useState<SelectStruct[]>([
    { label: '', value: '' },
  ])
  const [allRuleFailureOptions, setAllRuleFailureOptions] = useState<SelectStruct[]>([
    { label: '', value: '' },
  ])
  const [allNewRuleOptions, setAllNewRuleOptions] = useState<SelectStruct[]>([
    { label: '', value: '' },
  ])
  const [allRuleNameOptions, setAllRuleNameOptions] = useState<SelectStruct[]>([
    { label: '', value: '' },
  ])
  const [selectedRuleSuccessOption, setSelectedRuleSuccessOption] = useState<SelectStruct>({
    label: '',
    value: '',
  })
  const [selectedRuleFailureOption, setSelectedRuleFailureOption] = useState<SelectStruct>({
    label: '',
    value: '',
  })
  const [selectedNewRuleOption, setSelectedNewRuleOption] = useState<SelectStruct>({
    label: '',
    value: '',
  })

  const [lfTmpNodes, setLfTmpNodes] = useState<Node[]>(initNodes)
  const [lfTmpEdges, setLfTmpEdges] = useState<Edge[]>(initEdges)
  const [lfTmpRuleParams, setLFTmpRuleParams] = useState(ruleParams)
  const [lfTmpShadowModeRules, setLfTmpShadowModeRules] = useState(new Map())
  const [lastStepNames, setLastStepNames] = useState(new Set())
  const [addRuleModal, setAddRuleModal] = useState(false)
  const allFraudChecks: string[] = Array.from(Object.keys(FraudChecks).values())
  const [inUseFraudChecks, setInUseFraudChecks] = useState<string[]>([])
  const [displayValidateErrorMessage, setDisplayValidateErrorMessage] = useState<[boolean, string]>(
    [false, '']
  )
  const dispatch = useDispatch()

  useEffect(() => {
    if (nodes.length === 0 && edges.length === 0) {
      dispatch(setNodes(initNodes))
      dispatch(setEdges(initEdges))
      setLfTmpNodes(initNodes)
      setLfTmpEdges(initEdges)
    }
  }, [initNodes, initEdges])

  useEffect(() => {
    const allInUseRule = new Set<string>()
    nodes.forEach((n) => {
      if (!n.id.includes(leafNodeIdDelimiter)) {
        allInUseRule.add(n.id)
      }
    })
    if (
      allInUseRule.size !== inUseFraudChecks.length ||
      !inUseFraudChecks.every((s) => allInUseRule.has(s))
    ) {
      setInUseFraudChecks(Array.from(allInUseRule))
    }
  }, [nodes])

  /* allRuleNameOptions is being used later to determine the list of onSuccess/onFailure options per rule */
  useEffect(() => {
    const notAddedRules = allFraudChecks.filter((n) => inUseFraudChecks.indexOf(n) === -1)
    const allNewOptions: SelectStruct[] = []
    notAddedRules.map((r) => {
      allNewOptions.push({ label: r, value: r })
    })
    const allInUseOptions: SelectStruct[] = inUseFraudChecks.map((s) => {
      return { label: s, value: s }
    })
    setAllRuleNameOptions(allInUseOptions)
    setAllNewRuleOptions(allNewOptions)
  }, [inUseFraudChecks])

  const onNodeClick = (_: React.MouseEvent, node: Node) => {
    return clickNode(node)
  }

  useEffect(() => {
    const options = allRuleNameOptions.filter((opt) => opt.label !== clickedSelectedRuleName)
    const successOptions = options.concat([
      {
        label: successAllCap,
        value: clickedSelectedRuleName + leafNodeIdDelimiter + successAllCap,
      },
      {
        label: failureAllCap,
        value: clickedSelectedRuleName + leafNodeIdDelimiter + failureAllCap,
      },
    ])
    const failureOptions = options.concat([
      {
        label: failureAllCap,
        value: clickedSelectedRuleName + leafNodeIdDelimiter + failureAllCap,
      },
      {
        label: successAllCap,
        value: clickedSelectedRuleName + leafNodeIdDelimiter + successAllCap,
      },
    ])
    setAllRuleSuccessOptions(successOptions)
    setAllRuleFailureOptions(failureOptions)

    if (clickedSelectedRuleName !== ruleNameUnselected) {
      const successEdge = edges.find((e) =>
        e.id.startsWith(clickedSelectedRuleName + successEdgeDelimiter)
      )
      const successLabelValue = successEdge!.target.includes(leafNodeIdDelimiter)
        ? successEdge!.target.substring(successEdge!.target.length - suffixLength)
        : successEdge!.target
      setSelectedRuleSuccessOption({
        label: successLabelValue,
        value: successLabelValue,
      })
      const failureEdge = edges.find((e) =>
        e.id.startsWith(clickedSelectedRuleName + failureEdgeDelimiter)
      )
      const failureLabelValue = failureEdge!.target.includes(leafNodeIdDelimiter)
        ? failureEdge!.target.substring(failureEdge!.target.length - suffixLength)
        : failureEdge!.target
      setSelectedRuleFailureOption({
        label: failureLabelValue,
        value: failureLabelValue,
      })
    }
  }, [allRuleNameOptions, clickedSelectedRuleName])

  const clickNode = (node: Node) => {
    console.log('click', node.id)
    dispatch(resetNodeStyle())
    dispatch(setSelectedNodeStyle(node))
    /* When user select a fraud check node */
    if (!node.id.includes(leafNodeIdDelimiter)) {
      dispatch(setClickedSelectedRuleName(node.id))
      dispatch(setCurEditRule([node.id, false]))
      setLfTmpEdges([...edges])
      setLfTmpNodes([...nodes])
      setLFTmpRuleParams(new Map(ruleParams))
    } else {
      dispatch(setClickedSelectedRuleName(ruleNameUnselected))
      setSelectedRuleSuccessOption({ label: '', value: '' })
      setSelectedRuleFailureOption({ label: '', value: '' })
      setAllRuleSuccessOptions([{ label: '', value: '' }])
      setAllRuleFailureOptions([{ label: '', value: '' }])
    }
  }

  const onSuccessSelectedOptionChange = ({ detail }) => {
    const onSuccess = detail.selectedOption.label
    console.log('onsuccess: ', onSuccess)

    const [curSuccessChildNode, curFailureChildNode] = getChildNodesFromEdge(
      lfTmpEdges,
      clickedSelectedRuleName
    )
    const successLeafNode = generateSuccessLeafNode(clickedSelectedRuleName)
    const failureLeafNode = generateFailureLeafNode(clickedSelectedRuleName)
    if (onSuccess === successAllCap) {
      if (curSuccessChildNode === curFailureChildNode) {
        setLfTmpNodes([...lfTmpNodes, { ...successLeafNode }])
      } else {
        setLfTmpNodes([
          ...lfTmpNodes.filter((node) => node.id !== curSuccessChildNode),
          { ...successLeafNode },
        ])
      }
    } else if (onSuccess === failureAllCap) {
      if (curSuccessChildNode === curFailureChildNode) {
        setLfTmpNodes([...lfTmpNodes, { ...failureLeafNode }])
      } else {
        setLfTmpNodes([
          ...lfTmpNodes.filter((node) => node.id !== curSuccessChildNode),
          { ...failureLeafNode },
        ])
      }
    } else {
      if (curSuccessChildNode !== curFailureChildNode)
        setLfTmpNodes([...lfTmpNodes.filter((node) => node.id !== curSuccessChildNode)])
    }

    let edgeTarget = onSuccess
    if (onSuccess === failureAllCap)
      edgeTarget = clickedSelectedRuleName + leafNodeIdDelimiter + failureAllCap
    else if (onSuccess === successAllCap)
      edgeTarget = clickedSelectedRuleName + leafNodeIdDelimiter + successAllCap
    setLfTmpEdges([
      ...lfTmpEdges.filter(
        (edge) => !edge.id.startsWith(clickedSelectedRuleName + successEdgeDelimiter)
      ),
      {
        ...{
          id: clickedSelectedRuleName + successEdgeDelimiter + onSuccess,
          source: clickedSelectedRuleName,
          target: edgeTarget,
          type: edgeType,
          style: { stroke: '#98C91E' },
          markerEnd: {
            type: MarkerType.ArrowClosed,
            color: '#98C91E',
          },
        },
      },
    ])

    setSelectedRuleSuccessOption({
      label: detail.selectedOption.label!,
      value: detail.selectedOption.value!,
    })
  }

  const onFailureSelectedOptionChange = ({ detail }) => {
    const onFailure = detail.selectedOption.label
    console.log('onFailure: ', onFailure)

    const [curSuccessChildNode, curFailureChildNode] = getChildNodesFromEdge(
      lfTmpEdges,
      clickedSelectedRuleName
    )
    const successLeafNode = generateSuccessLeafNode(clickedSelectedRuleName)
    const failureLeafNode = generateFailureLeafNode(clickedSelectedRuleName)
    if (onFailure === successAllCap) {
      if (curSuccessChildNode === curFailureChildNode) {
        setLfTmpNodes([...lfTmpNodes, { ...successLeafNode }])
      } else {
        setLfTmpNodes([
          ...lfTmpNodes.filter((node) => node.id !== curFailureChildNode),
          { ...successLeafNode },
        ])
      }
    } else if (onFailure === failureAllCap) {
      if (curSuccessChildNode === curFailureChildNode) {
        setLfTmpNodes([...lfTmpNodes, { ...failureLeafNode }])
      } else {
        setLfTmpNodes([
          ...lfTmpNodes.filter((node) => node.id !== curFailureChildNode),
          { ...failureLeafNode },
        ])
      }
    } else {
      if (curSuccessChildNode !== curFailureChildNode)
        setLfTmpNodes([...lfTmpNodes.filter((node) => node.id !== curFailureChildNode)])
    }

    let edgeTarget = onFailure
    if (onFailure === failureAllCap)
      edgeTarget = clickedSelectedRuleName + leafNodeIdDelimiter + failureAllCap
    else if (onFailure === successAllCap)
      edgeTarget = clickedSelectedRuleName + leafNodeIdDelimiter + successAllCap
    setLfTmpEdges([
      ...lfTmpEdges.filter(
        (edge) => !edge.id.startsWith(clickedSelectedRuleName + failureEdgeDelimiter)
      ),
      {
        ...{
          id: clickedSelectedRuleName + failureEdgeDelimiter + onFailure,
          source: clickedSelectedRuleName,
          target: edgeTarget,
          type: edgeType,
          style: { stroke: '#AD0A30' },
          markerEnd: {
            type: MarkerType.ArrowClosed,
            color: '#AD0A30',
          },
        },
      },
    ])

    setSelectedRuleFailureOption({
      label: detail.selectedOption.label!,
      value: detail.selectedOption.value!,
    })
  }

  const onDeactivate = () => {
    console.log('on Deactivate')
    dispatch(setCurEditRuleShadowMode([clickedSelectedRuleName, true]))
    const new_status = new Map(lfTmpShadowModeRules)
    new_status.set(clickedSelectedRuleName, true)
    setLfTmpShadowModeRules(new_status)
    const curFailure = selectedRuleFailureOption.label
    const curSuccess = selectedRuleSuccessOption.label
    if (curFailure === failureAllCap || curFailure === successAllCap) {
      setSelectedRuleFailureOption({ label: curSuccess, value: curSuccess })
      setLfTmpNodes([
        ...lfTmpNodes.filter(
          (node) => node.id !== clickedSelectedRuleName + leafNodeIdDelimiter + curFailure
        ),
      ])
      setLfTmpEdges([
        ...lfTmpEdges.filter(
          (edge) => !edge.id.startsWith(clickedSelectedRuleName + failureEdgeDelimiter)
        ),
        {
          ...{
            id: clickedSelectedRuleName + failureEdgeDelimiter + curSuccess,
            source: clickedSelectedRuleName,
            target: curSuccess,
            type: edgeType,
            style: { stroke: '#AD0A30' },
            markerEnd: {
              type: MarkerType.ArrowClosed,
              color: '#AD0A30',
            },
          },
        },
      ])
    } else if (curSuccess === successAllCap || curSuccess === failureAllCap) {
      setSelectedRuleSuccessOption({ label: curFailure, value: curFailure })
      setLfTmpNodes([
        ...lfTmpNodes.filter(
          (node) => node.id !== clickedSelectedRuleName + leafNodeIdDelimiter + curSuccess
        ),
      ])
      setLfTmpEdges([
        ...lfTmpEdges.filter(
          (edge) => !edge.id.startsWith(clickedSelectedRuleName + successEdgeDelimiter)
        ),
        {
          ...{
            id: clickedSelectedRuleName + successEdgeDelimiter + curFailure,
            source: clickedSelectedRuleName,
            target: curFailure,
            type: edgeType,
            style: { stroke: '#98C91E' },
            markerEnd: {
              type: MarkerType.ArrowClosed,
              color: '#98C91E',
            },
          },
        },
      ])
    }
  }

  const onSelectedNewRuledOptionChange = ({ detail }) => {
    setSelectedNewRuleOption({
      label: detail.selectedOption.label,
      value: detail.selectedOption.value,
    })
    const newRule = detail.selectedOption.label
    setAllRuleNameOptions([...allRuleNameOptions, { label: newRule, value: newRule }])
  }

  function saveNewRule() {
    const newRule = selectedNewRuleOption.label

    const tmp_nodes = [
      ...nodes,
      {
        ...{
          id: newRule,
          data: { label: newRule },
          position,
          style: { border: '1px solid #6C7778', width: 'auto' },
        },
      },
      { ...generateSuccessLeafNode(newRule) },
      { ...generateFailureLeafNode(newRule) },
    ]

    const tmp_edges = [
      ...edges,
      {
        ...{
          id: newRule + failureEdgeDelimiter + failureAllCap,
          source: newRule,
          target: newRule + leafNodeIdDelimiter + failureAllCap,
          type: edgeType,
          style: { stroke: '#AD0A30' },
          markerEnd: {
            type: MarkerType.ArrowClosed,
            color: '#AD0A30',
          },
        },
      },
      {
        ...{
          id: newRule + successEdgeDelimiter + successAllCap,
          source: newRule,
          target: newRule + leafNodeIdDelimiter + successAllCap,
          type: edgeType,
          style: { stroke: '#98C91E' },
          markerEnd: {
            type: MarkerType.ArrowClosed,
            color: '#98C91E',
          },
        },
      },
    ]

    dispatch(setNodes(tmp_nodes))
    dispatch(setEdges(tmp_edges))
    setLfTmpNodes(tmp_nodes)
    setLfTmpEdges(tmp_edges)
  }

  const onNodesChange = (changes: NodeChange[]) => {
    if (changes[0].type === 'remove' && !changes[0].id.includes(leafNodeIdDelimiter)) {
      changes = [
        ...changes,
        {
          ...{
            type: 'remove',
            id: changes[0].id + leafNodeIdDelimiter + successAllCap,
          },
        },
        {
          ...{
            type: 'remove',
            id: changes[0].id + leafNodeIdDelimiter + failureAllCap,
          },
        },
      ]
      dispatch(setClickedSelectedRuleName(ruleNameUnselected))
    }
    dispatch(setNodes(applyNodeChanges(changes, nodes)))
  }

  const onEdgesChange = (changes: EdgeChange[]) => {
    dispatch(setEdges(applyEdgeChanges(changes, edges)))
  }

  function saveLocalChanges() {
    dispatch(setNodes([...lfTmpNodes]))
    dispatch(setEdges([...lfTmpEdges]))
    dispatch(setRuleParams(convertParamsToObject(lfTmpRuleParams)))
  }

  /* Convert the local edited init edges into json format */
  useEffect(() => {
    const nodeMap = convertEdgesToFraudCheckStepMap(edges)
    const values: FraudCheckStep[] = Array.from(nodeMap.values())
    values.map((rule) => {
      if (ruleParams.has(rule.ruleName)) {
        rule.parameters = ruleParamsJson[rule.ruleName]
      }
    })
    dispatch(setStepJsonVal(values))
  }, [edges, ruleParamsJson])

  useEffect(() => {
    console.log('update rule status')
    const last_steps = new Set()
    const shadow_mode_rules = new Map()
    stepJsonVal.forEach((r) => {
      if (
        (r.onFailure === successAllCap || r.onFailure === failureAllCap) &&
        (r.onSuccess === successAllCap || r.onSuccess === failureAllCap)
      ) {
        last_steps.add(r.ruleName)
        shadow_mode_rules.set(r.ruleName, true)
      } else if (r.onSuccess === successAllCap || r.onSuccess === failureAllCap) {
        shadow_mode_rules.set(r.ruleName, false)
      } else if (r.onFailure === successAllCap || r.onFailure === failureAllCap) {
        shadow_mode_rules.set(r.ruleName, false)
      } else {
        shadow_mode_rules.set(r.ruleName, true)
      }
    })
    setLastStepNames(last_steps)
    setLfTmpShadowModeRules(shadow_mode_rules)
  }, [stepJsonVal])

  useEffect(() => {
    if (edges.length > 0) {
      const [isTreeValid, steps, rulesetMessage] = validateSortTree(stepJsonVal)
      const [isParamsValid, params, paramMessage] = getValidParameters(
        ruleParamsJson,
        ruleParams,
        stepJsonVal
      )
      if (isTreeValid && isParamsValid) {
        setDisplayValidateErrorMessage([false, ''])
        return
      }
      let errMessage = ''
      if (!isTreeValid) errMessage = rulesetMessage
      if (!isParamsValid) errMessage += paramMessage
      setDisplayValidateErrorMessage([true, errMessage])
    }
  }, [stepJsonVal])

  function cancelModalEdit() {
    setAddRuleModal(false)
    setSelectedNewRuleOption({ label: '', value: '' })
  }

  useEffect(() => {
    if (allNewRuleOptions.length && allNewRuleOptions.indexOf(selectedNewRuleOption) === -1) {
      setSelectedNewRuleOption(allNewRuleOptions[0])
    }
  }, [allNewRuleOptions])

  useEffect(() => {
    setParameterEditor(
      clickedSelectedRuleName !== ruleNameUnselected && (
        <div className={'topLeft editForm' + ` ${curEditRule[1]}`}>
          <SpaceBetween size="xs" direction="vertical">
            <Header
              variant="h3"
              actions={
                curEditRule[1] ? (
                  <Button
                    variant="primary"
                    onClick={() => {
                      saveLocalChanges()
                      dispatch(setClickedSelectedRuleName(ruleNameUnselected))
                      dispatch(setCurEditRule(['', false]))
                      dispatch(setCurEditRuleShadowMode(['', false]))
                      dispatch(resetNodeStyle())
                    }}
                  >
                    Done
                  </Button>
                ) : (
                  <Button
                    iconName="edit"
                    onClick={() => {
                      dispatch(setCurEditRule([clickedSelectedRuleName, true]))
                      const canDeactivate =
                        selectedRuleFailureOption.label === failureAllCap ||
                        selectedRuleSuccessOption.label === successAllCap ||
                        selectedRuleFailureOption.label === successAllCap ||
                        selectedRuleSuccessOption.label === failureAllCap
                      dispatch(
                        setCurEditRuleShadowMode([
                          clickedSelectedRuleName,
                          lastStepNames.has(clickedSelectedRuleName) || !canDeactivate,
                        ])
                      )
                    }}
                  >
                    Edit
                  </Button>
                )
              }
            >
              <div className="ruleName">{getFraudCheckDisplayName(clickedSelectedRuleName)}</div>
            </Header>

            <Button
              variant="link"
              iconName="status-stopped"
              onClick={() => {
                onDeactivate()
              }}
              disabled={!curEditRule[1] || curEditRuleShadowMode[1]}
            >
              Deactivate
            </Button>

            <div>
              <div className="sectionName">Description</div>
              <div className="description">
                {FraudCheckDescriptions.has(clickedSelectedRuleName)
                  ? FraudCheckDescriptions.get(clickedSelectedRuleName)
                  : 'Description coming soon...'}
              </div>
            </div>

            {(FraudCheckRuleDetailsFailure.has(clickedSelectedRuleName) ||
              FraudCheckRuleDetailsSuccess.has(clickedSelectedRuleName)) && (
              <div>
                <div className="sectionName">Rule Details</div>
                {FraudCheckRuleDetailsSuccess.has(clickedSelectedRuleName) &&
                  Array.from(FraudCheckRuleDetailsSuccess.get(clickedSelectedRuleName)!.keys()).map(
                    (param) => (
                      <div className="ruleDetails" key={param}>
                        <img src={passReason} className="detailIcon" />
                        {FraudCheckRuleDetailsSuccess.get(clickedSelectedRuleName)!.get(param)}
                      </div>
                    )
                  )}
                {FraudCheckRuleDetailsFailure.has(clickedSelectedRuleName) &&
                  Array.from(FraudCheckRuleDetailsFailure.get(clickedSelectedRuleName)!.keys()).map(
                    (param) => (
                      <div className="ruleDetails" key={param}>
                        <img src={failReason} className="detailIcon" />
                        {FraudCheckRuleDetailsFailure.get(clickedSelectedRuleName)!.get(param)}
                      </div>
                    )
                  )}
              </div>
            )}

            {ruleParams.has(clickedSelectedRuleName) &&
              ruleParams.get(clickedSelectedRuleName)!.size > 0 && (
                <div>
                  <div className="sectionName">Advanced Settings</div>
                  {Array.from(ruleParams.get(clickedSelectedRuleName)!.keys()).map((param) => (
                    <div key={param}>
                      <div className="paramName">{param}</div>
                      <Input
                        onChange={({ detail }) => {
                          const all_rules_params = new Map(lfTmpRuleParams)
                          const cur_rule_params = new Map(
                            lfTmpRuleParams.get(clickedSelectedRuleName)!
                          )
                          cur_rule_params.set(
                            param,
                            detail.value.trim().length === 0 ? [] : detail.value.split(',')
                          )
                          all_rules_params.set(clickedSelectedRuleName, cur_rule_params)
                          setLFTmpRuleParams(all_rules_params)
                        }}
                        value={
                          lfTmpRuleParams.has(clickedSelectedRuleName) &&
                          lfTmpRuleParams.get(clickedSelectedRuleName)!.get(param)
                            ? lfTmpRuleParams.get(clickedSelectedRuleName)!.get(param)!.join(',')
                            : ''
                        }
                        readOnly={!curEditRule[1]}
                        disabled={!curEditRule[1]}
                      />
                    </div>
                  ))}
                </div>
              )}

            <div className="sectionName">On success</div>
            <Select
              selectedOption={selectedRuleSuccessOption}
              onChange={onSuccessSelectedOptionChange}
              options={allRuleSuccessOptions}
              selectedAriaLabel="Selected"
              disabled={!curEditRule[1]}
            />

            <div className="sectionName">On failure</div>
            <Select
              selectedOption={selectedRuleFailureOption}
              onChange={onFailureSelectedOptionChange}
              options={allRuleFailureOptions}
              selectedAriaLabel="Selected"
              disabled={!curEditRule[1]}
            />
          </SpaceBetween>
        </div>
      )
    )
  }, [
    clickedSelectedRuleName,
    curEditRule,
    allRuleSuccessOptions,
    allRuleSuccessOptions,
    selectedRuleFailureOption,
    selectedRuleSuccessOption,
    lfTmpRuleParams,
  ])

  return (
    <SpaceBetween size="m">
      {displayValidateErrorMessage[0] && (
        <Alert
          onDismiss={() => setDisplayValidateErrorMessage([false, ''])}
          visible={displayValidateErrorMessage[0]}
          dismissible
          type="error"
          dismissAriaLabel="Close alert"
          header="Validation error"
        >
          {displayValidateErrorMessage[1]}
        </Alert>
      )}
      <div className="layoutflow">
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onNodeClick={onNodeClick}
          nodesConnectable={false}
          nodesDraggable={false}
          fitView
        />
      </div>
    </SpaceBetween>
  )
}
