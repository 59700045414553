import { FraudChecks } from './FraudChecks'

export const FraudCheckRuleDetailsFailure = new Map<string, Map<string, string>>([
  [
    FraudChecks.AccountStatusCheck,
    new Map<string, string>([
      [
        'HAS_UNAUTHENTICABLE_ACCOUNT_STATUS',
        'Customer contains an invalid account status.',
      ],
    ]),
  ],
  [
    FraudChecks.ActiveSubscriptionCheck,
    new Map<string, string>([
      [
        'HAS_NO_VALID_ACTIVE_SUBSCRIPTION',
        "Customer doesn't have an active subscription.",
      ],
    ]),
  ],
  [
    FraudChecks.ChargeCompletedCheck,
    new Map<string, string>([
      ['HAS_NO_SUBSCRIPTION_CHARGE', 'No subscription charge found for customer.'],
      ['HAS_NOT_COMPLETED_CHARGE', 'Customer has not completed most recent subscription charge.'],
    ]),
  ],
  [
    FraudChecks.ChargeStatusCheck,
    new Map<string, string>([
      [
        'CHARGE_DECLINED',
        "Processor or bank rejected customer's credit card for enhanced vet.",
      ],
      [
        'AVS_CODE_INVALID',
        "The customer's address that they entered in their account for their payment method doesn't exactly match the address that is found on the bank's record.",
      ],
      [
        'NO_ELIGIBLE_CC_VET_NOT_ATTEMPTED',
        "Customer either 1) Has no active credit cards 2) All active credit cards in the customer's wallet failed previous fraud checks or are ineligible for enhanced vet.",
      ],
    ]),
  ],
  [
    FraudChecks.HighVelocityIssuingBankCheck,
    new Map<string, string>([
      [
        'HAS_HIGH_VELOCITY_ISSUING_BANK',
        "Customer's payment method is flagged as part of a high velocity issuing bank event.",
      ],
    ]),
  ],
  [
    FraudChecks.MobilePhoneCheck,
    new Map<string, string>([
      [
        'HAS_NO_VALID_MOBILE_COUNTRY_CODE',
        'Customer has a verified mobile phone number on their Amazon account that belongs to a region that is not in the allow list.',
      ],
      [
        'HAS_NO_VERIFIED_PHONE_NUMBER',
        "The customer does not currently have a verified mobile phone number on their Amazon account.",
      ],
      [
        'GEOGRAPHICAL_DETAIL_MISSING',
        "Unable to retrieve mobile phone number information for the customer. ",
      ],
    ]),
  ],
  [
    FraudChecks.MobileTokenLimitCheck,
    new Map<string, string>([
      [
       'GEOGRAPHICAL_DETAIL_MISSING',
       'Unable to retrieve mobile phone number information for the customer.'
      ],
      [
        'HAS_NO_MOBILE_TOKEN',
       'Customer does not have a verified mobile phone number or mobile token not found. '
      ],
      [
        'HAS_EXCEEDED_MOBILE_TOKEN_LIMIT',
        'Customer has used the same mobile phone number for sign up more than the allowed limit.',
      ],
    ]),
  ],
  [
    FraudChecks.RequiredPrimeBenefitsCheck,
    new Map<string, string>([
      [
        'HAS_MISSING_PRIME_BENEFITS_OR_INVALID_FACETS',
        "Customer either has or both 1) Has billing problem with one of their Prime or Prime Video subscriptions. 2) Doesn't have Prime / Prime Video subscription.",
      ],
      [
        'PRIME_SUBSCRIPTION_DETAIL_MISSING',
        "Fraud checks were unable to find Prime / Prime Video subscription information on the customer's account.",
      ],
    ]),
  ],
  [
    FraudChecks.RiskContextCheck,
    new Map<string, string>([
      [
      'HAS_INVALID_RISK_CONTEXT',
       'Customer has invalid risk context.'
      ]
    ]),
  ],
  [
    FraudChecks.TokenLimitCheck,
    new Map<string, string>([
      [
        'HAS_NO_VALID_ACTIVE_PAYMENT_METHOD',
        'Customer either 1) Has no active payment method/instrument 2) All active payment methods/instruments failed previous fraud checks or are ineligible.',
      ],
      [
        'HAS_EXCEEDED_TOKEN_LIMIT',
        "Customer has at least one payment method/instrument which has exceeded token limit threshold.",
      ],
    ]),
  ],
  [
    FraudChecks.TwoFactorAuthenticationCheck,
    new Map<string, string>([
      [
        'NEEDS_MOBILE_VERIFICATION',
        "Customer either 1) Hasn't verified their phone number on their Amazon account 2) Customer has a verified mobile phone number from an invalid country.",
      ],
    ]),
  ],
  [
    FraudChecks.DigitalOrdersCheck,
    new Map<string, string>([
      [
        'HAS_NO_DIGITAL_ORDERS_PURCHASE',
        'Customer has no digital orders within a specific time frame that exceed the minimum purchase value.',
      ],
      ['DIGITAL_ORDER_DETAIL_MISSING',
       'No associated digital order detail found for customer.'
      ],
    ]),
  ],
  [
    FraudChecks.EUMultiFactorAuthenticationCheck,
    new Map<string, string>([
      [
        'NO_EU_MFA_REQUIRED',
        'Customer does not require multi-factor authentication for their charge.',
      ],
      [
        'HAS_UNVERIFIED_EU_MFA',
        'Customer requires multi-factor authentication for their charge, but their recurring payment source is pending verification.',
      ],
    ]),
  ],
  [
    FraudChecks.ExternallyManagedPaymentCheck,
    new Map<string, string>([
      [
        'HAS_NO_EXTERNALLY_MANAGED_PAYMENT',
        'Customer does not have external payment methods.'
      ],
      [
        'HAS_UNSUPPORTED_EXTERNALLY_MANAGED_PAYMENT',
        'Customer has an unsupported external payment method (not Apple or Google Pay).',
      ],
    ]),
  ],
  [
    FraudChecks.FuseCheck,
    new Map<string, string>([
      [
        'PRIME_SUBSCRIPTION_DETAIL_MISSING',
        'Customer has no available subscription details.',
      ],
      [
        'HAS_NO_FUSE_SUBSCRIPTION',
        'Customer is not part of Fuse program.'
      ],
    ]),
  ],
  [
    FraudChecks.GiftCardCheck,
    new Map<string, string>([
      ['PAYMENT_METHOD_DETAIL_MISSING', 'No payment method details found for customer.'],
      ['HAS_CREDIT_CARDS', 'Customer has credit cards in their wallet.'],
      ['HAS_NO_ACTIVE_PREFERRED_GIFT_CARD', 'Customer has no active preferred gift cards.'],
    ]),
  ],
  [
    FraudChecks.GiftedPrimeCheck,
    new Map<string, string>([
      [
        'PRIME_SUBSCRIPTION_DETAIL_MISSING',
        'Customer has no associated Prime subscription detail.',
      ],
      ['HAS_NO_GIFTED_PRIME', "Customer's Prime subscription is not gifted."],
    ]),
  ],
  [
    FraudChecks.MobileCarrierBillingCheck,
    new Map<string, string>([
      [
        'HAS_NO_ACTIVE_PREFERRED_MOBILE_CARRIER_BILLING',
        'Customer has no active preferred mobile carrier billing method.',
      ],
    ]),
  ],
  [
    FraudChecks.PaidTenureCheck,
    new Map<string, string>([
      [
        'HAS_FREE_TRIAL_ACCOUNT',
        'Customer is on a free trial subscription.',
      ],
      [
        'HAS_NO_PAID_TENURE',
        "Customer either 1) Doesn't have a nonzero paid subscription payment history. 2) Has failed at least one of their most recent subscription payments.",
      ],
    ]),
  ],
  [
    FraudChecks.PhysicalOrdersCheck,
    new Map<string, string>([
      ['PHYSICAL_ORDER_DETAIL_MISSING', 'Customer is has no associated physical order detail.'],
      [
        'HAS_NO_PHYSICAL_ORDERS_PURCHASE',
        'Customer has no physical orders within a specific time window that exceed the minimum number of purchase orders.',
      ],
    ]),
  ],
  [
    FraudChecks.DirectDebitCheck,
    new Map<string, string>([
      [
        'IS_NOT_DIRECT_DEBIT',
        'Customer does not have a valid preferred international bank account.',
      ],
      [
        'HAS_INVALID_BANK_ACCOUNT',
        'Customer signs up with bank name that is in the denied list.',
      ],
    ]),
  ],
  [
    FraudChecks.IdealCheck,
    new Map<string, string>([
      [
        'HAS_NO_IDEAL_PAYMENT_METHOD',
        "Customer does not have a valid preferred iDeal payment method.",
      ],
    ]),
  ],
  [
    FraudChecks.PrepaidCreditCardCheck,
    new Map<string, string>([
      [
        'PREPAID_CREDIT_CARD_NOT_SUPPORTED_IN_MARKETPLACE',
        'Customer does not have a prepaid card that is valid in current marketplace.',
      ],
    ]),
  ],
  [
    FraudChecks.BancontactCheck,
    new Map<string, string>([
      [
        'HAS_NO_BANCONTACT_PAYMENT_METHOD',
        "Customer does not have valid preferred Bancontact payment method.",
      ],
    ]),
  ],
  [
    FraudChecks.UnifiedPaymentsInterfaceCheck,
    new Map<string, string>([
      [
        'HAS_NO_UPI_PAYMENT_METHOD',
        "Customer does not have valid preferred UPI payment method.",
      ],
    ]),
  ],
  [
    FraudChecks.EmandateCheck,
    new Map<string, string>([
      [
        'HAS_NO_VERIFIED_EMANDATE',
        'Customer has no "EMANDATE_AGREED" tag in their Prime subscription, the membership can not be auto renewed.',
      ],
    ]),
  ],
  [
    FraudChecks.DigitalWalletCheck,
    new Map<string, string>([
      [
        'HAS_NO_DIGITAL_WALLET', 'Customer does not have valid preferred digital wallet payment method.',
      ],
    ]),
  ],
])

export const FraudCheckRuleDetailsSuccess = new Map<string, Map<string, string>>([
  [
    FraudChecks.AccountStatusCheck,
    new Map<string, string>([
      ['HAS_NO_AVAILABLE_ACCOUNT_STATUS', 'No account status is found for this customer.'],
      [
        'HAS_NORMAL_ACCOUNT_STATUS',
        'Customer have valid account status.',
      ],
    ]),
  ],
  [
    FraudChecks.ActiveSubscriptionCheck,
    new Map<string, string>([
      ['HAS_FREE_TRIAL_ACCOUNT', 'Customer has free trial subscription.'],
      ['HAS_PAID_ACCOUNT', 'Customer has paid subscription.'],
    ]),
  ],
  [
    FraudChecks.ChargeCompletedCheck,
    new Map<string, string>([
      ['HAS_COMPLETED_CHARGE', 'Customer has completed most recent subscription charge.'],
    ]),
  ],
  [
    FraudChecks.ChargeStatusCheck,
    new Map<string, string>([
      [
        'CHARGE_TIMEOUT',
        "Due to one of following 1) Downstream dependency failure/outage 2) Bank/Processor failed to process customer's credit card 3) Enhanced vet took too long to process by bank/processor.",
      ],
      [
        'BANK_ACCOUNT_FOUND',
        'Customer has valid preferred bank account which cannot undergo enhanced vet.',
      ],
      ['CHARGE_APPROVED', "Enhanced vet successfully approved on customer's credit card. "],
      ['AVS_CODE_VALID', "Customer's AVS code response from enhanced vet is valid."],
      [
        'NOT_ELIGIBLE_FOR_CHARGE_STATUS_CHECK',
        'Customer failed prerequisites to have credit card undergo enhanced vet.',
      ],
      [
        'DEBIT_CARD_BYPASS',
        'Brazil customer has debit card and cannot undergo enhanced vet (cancel operation is not supported by the AdyenDebit processor).',
      ],
      [
        'HAS_VALID_PROCESSOR_STATUS_CODE',
        'Processor status code returned from performing enhanced vet is valid.',
      ],
    ]),
  ],
  [
    FraudChecks.HighVelocityIssuingBankCheck,
    new Map<string, string>([
      [
        'NOT_ELIGIBLE_FOR_HIGH_VELOCITY_ISSUING_BANK_CHECK',
        "Customer doesn't meet the requirement to run the check.",
      ],
      [
        'HAS_NO_HIGH_VELOCITY_ISSUING_BANK',
        "Customer's payment method is not part of a high velocity event.",
      ],
      [
        'HAS_ALLOWLISTED_HIGH_VELOCITY_ISSUING_BANK',
        "Country code of the issuing bank of the customer's payment method is allowlisted.",
      ],
    ]),
  ],
  [
    FraudChecks.MobilePhoneCheck,
    new Map<string, string>([
      [
        'HAS_VALID_MOBILE_COUNTRY_CODE',
        'Customer has a mobile phone number with an allowed country code.',
      ],
    ]),
  ],
  [
    FraudChecks.MobileTokenLimitCheck,
    new Map<string, string>([
      [
        'HAS_NOT_EXCEEDED_MOBILE_TOKEN_LIMIT',
        'Customer has used valid mobile phone number for sign up within the allowed limit.',
      ],
    ]),
  ],
  [
    FraudChecks.RequiredPrimeBenefitsCheck,
    new Map<string, string>([
      ['HAS_REQUIRED_PRIME_BENEFITS', 'Customer has all required Prime facets.'],
    ]),
  ],
  [
    FraudChecks.RiskContextCheck,
    new Map<string, string>([
      ['RISK_CONTEXT_DETAIL_MISSING', 'No risk context detail was found for the customer.'],
      ['HAS_VALID_RISK_CONTEXT', 'Customer has no invalid risk contexts.'],
    ]),
  ],
  [
    FraudChecks.TokenLimitCheck,
    new Map<string, string>([
      [
        'HAS_NOT_EXCEEDED_TOKEN_LIMIT',
        "Customer successfully signed up with this payment method within the allowed limit",
      ],
    ]),
  ],
  [
    FraudChecks.TwoFactorAuthenticationCheck,
    new Map<string, string>([
      [
        'MOBILE_VERIFICATION_NOT_REQUIRED',
        "No mobile verification needed for sign up.",
      ],
    ]),
  ],
  [
    FraudChecks.DigitalOrdersCheck,
    new Map<string, string>([
      [
        'HAS_DIGITAL_ORDERS_PURCHASE',
        'Customer has digital orders within a specific time frame that meet the minimum purchase value.',
      ],
    ]),
  ],
  [
    FraudChecks.EUMultiFactorAuthenticationCheck,
    new Map<string, string>([
      [
        'HAS_VERIFIED_EU_MFA',
        'Customer requires multi-factor authentication for their charge, and their recurring payment source has been verified.',
      ],
    ]),
  ],
  [
    FraudChecks.ExternallyManagedPaymentCheck,
    new Map<string, string>([
      [
        'HAS_ACTIVE_SUPPORTED_EXTERNALLY_MANAGED_PAYMENT',
        'Customer has Apple Pay or Google Pay as payment method.',
      ],
    ]),
  ],
  [
    FraudChecks.FuseCheck,
    new Map<string, string>([
      ['HAS_FUSE_SUBSCRIPTION', 'Customer is part of the Fuse program with Prime Video.'],
    ]),
  ],
  [
    FraudChecks.GiftCardCheck,
    new Map<string, string>([
      ['HAS_ACTIVE_PREFERRED_GIFT_CARD', 'Customer has active preferred gift card.'],
    ]),
  ],
  [
    FraudChecks.GiftedPrimeCheck,
    new Map<string, string>([['HAS_GIFTED_PRIME', "Customer's Prime subscription is gifted."]]),
  ],
  [
    FraudChecks.MobileCarrierBillingCheck,
    new Map<string, string>([
      [
        'HAS_ACTIVE_PREFERRED_MOBILE_CARRIER_BILLING',
        'Customer has an active preferred mobile carrier billing method.',
      ],
    ]),
  ],
  [
    FraudChecks.PaidTenureCheck,
    new Map<string, string>([['HAS_PAID_TENURE', 'Customer has successfully paid for their most recent XX subscription charges.']]),
  ],
  [
    FraudChecks.PhysicalOrdersCheck,
    new Map<string, string>([
      [
        'HAS_PHYSICAL_ORDERS_PURCHASE',
        'Customer has minimum number of physical orders within a specific time window in the past.',
      ],
    ]),
  ],
  [
    FraudChecks.DirectDebitCheck,
    new Map<string, string>([
      [
        'IS_DIRECT_DEBIT',
        'Customer has valid preferred international bank account.',
      ],
    ]),
  ],
  [
    FraudChecks.IdealCheck,
    new Map<string, string>([
      ['HAS_IDEAL_PAYMENT_METHOD', 'Customer has valid preferred iDeal payment method.'],
    ]),
  ],
  [
    FraudChecks.PrepaidCreditCardCheck,
    new Map<string, string>([
      ['WORLDWIDE_SUPPORT', 'Customer has prepaid credit card with a valid country code.'],
      [
        'PREPAID_CREDIT_CARD_SUPPORTED_IN_MARKETPLACE',
        'Customer has prepaid credit card that is allowed in current marketplace.',
      ],
      ['CREDIT_CARD_NOT_PREPAID', 'Customer has credit card that is not prepaid.'],
    ]),
  ],
  [
    FraudChecks.BancontactCheck,
    new Map<string, string>([
      ['HAS_BANCONTACT_PAYMENT_METHOD', 'Customer has valid preferred Bancontact payment method.'],
    ]),
  ],
  [
    FraudChecks.UnifiedPaymentsInterfaceCheck,
    new Map<string, string>([['HAS_UPI_PAYMENT_METHOD', 'Customer has valid preferred UPI payment method.']]),
  ],
  [
    FraudChecks.EmandateCheck,
    new Map<string, string>([
      [
        'HAS_VERIFIED_EMANDATE',
        'Customer has" EMANDATE_AGREED" tag in the Prime subscription, the membership auto renewal is on.',
      ],
    ]),
  ],
  [
    FraudChecks.DigitalWalletCheck,
    new Map<string, string>([
      [
        'HAS_DIGITAL_WALLET', 'Customer has valid preferred digital wallet payment method.',
      ],
    ]),
  ],
])
