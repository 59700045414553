import { Stage } from '../common/models/stage'

export const getStage = (): Stage => {
  const stageFromUrl = getStageFromUrl()

  if (stageFromUrl) {
    return stageFromUrl
  }

  const stageFromENV = getStageFromENV()

  if (stageFromENV) {
    return stageFromENV
  }

  return Stage.PRODUCTION
}

const getStageFromUrl = (): Stage | null => {
  console.log('init url: ', window.location.href)
  const stage = window.location.href.includes('fraudprevention.game-growth.amazon.dev')
    ? Stage.PRODUCTION
    : Stage.BETA
  console.log('init stage: ', stage)
  if (stage) {
    return getStageForString(stage)
  }

  return null
}

const getStageFromENV = (): Stage | null => {
  const stage = process.env.NODE_ENV === 'production' ? Stage.PRODUCTION : Stage.BETA

  if (stage) {
    return getStageForString(stage)
  }

  return null
}

const getStageForString = (stage: string): Stage => {
  return (Stage as { [key: string]: string })[stage.toUpperCase()] as Stage
}
