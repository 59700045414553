import React, { useState } from 'react'
import { Box, Button, FormField, Modal, SpaceBetween, Textarea } from '@amzn/awsui-components-react'
import { ApproveRequest, CancelRequest, RejectRequest } from '../../apis/ApprovalApi'
import { useSelector } from 'react-redux'
import { RootState } from '../../store/store'
import PropTypes from 'prop-types'

export const ApproveModal = ({ requestId, secondApproval, dismissCallback }) => {
  const userAlias = useSelector((state: RootState) => state.userInfo.userAlias)
  const [loading, setLoading] = React.useState(false)

  return (
    <Modal
      onDismiss={dismissCallback}
      visible={true}
      closeAriaLabel="Close modal"
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={dismissCallback}>
              Back
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                setLoading(true)
                ApproveRequest({ requestId, userAlias }).finally(dismissCallback)
              }}
              loading={loading}
            >
              Approve request
            </Button>
          </SpaceBetween>
        </Box>
      }
      header="Approve request"
    >
      {secondApproval
        ? 'Are you sure you want to approve this request? This will result in a ruleset update effective immediately.'
        : 'Are you sure you want to approve this request? This request will require an additional approval before the ruleset will be updated.'}
    </Modal>
  )
}

export const CancelModal = ({ requestId, dismissCallback }) => {
  const userAlias = useSelector((state: RootState) => state.userInfo.userAlias)
  const [loading, setLoading] = React.useState(false)

  return (
    <Modal
      onDismiss={dismissCallback}
      visible={true}
      closeAriaLabel="Close modal"
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={dismissCallback}>
              Back
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                setLoading(true)
                CancelRequest({ requestId, userAlias }).finally(dismissCallback)
              }}
              loading={loading}
            >
              Cancel request
            </Button>
          </SpaceBetween>
        </Box>
      }
      header="Cancel request"
    >
      Are you sure you want to cancel this request? This cannot be undone.
    </Modal>
  )
}

export const RejectModal = ({ requestId, dismissCallback }) => {
  const [reason, setReason] = React.useState('')
  const userAlias = useSelector((state: RootState) => state.userInfo.userAlias)
  const [loading, setLoading] = React.useState(false)

  return (
    <Modal
      onDismiss={dismissCallback}
      visible={true}
      closeAriaLabel="Close modal"
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={dismissCallback}>
              Back
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                setLoading(true)
                RejectRequest({ requestId, userAlias, reason }).finally(dismissCallback)
              }}
              loading={loading}
            >
              Reject request
            </Button>
          </SpaceBetween>
        </Box>
      }
      header="Reject request"
    >
      <FormField label="Rejection reason">
        <Textarea
          onChange={({ detail }) => setReason(detail.value)}
          value={reason}
          placeholder="Provide a reason for rejecting this request"
        />
      </FormField>
    </Modal>
  )
}

RejectModal.propTypes = {
  requestId: PropTypes.string,
  dismissCallback: PropTypes.func,
}

CancelModal.propTypes = {
  requestId: PropTypes.string,
  dismissCallback: PropTypes.func,
}

ApproveModal.propTypes = {
  requestId: PropTypes.string,
  secondApproval: PropTypes.bool,
  dismissCallback: PropTypes.func,
}
