import { config } from '../components/RulesetTool'
import axios from 'axios'

export interface Request {
  requestId: string
  treatmentId: string
  clientId: string
  requester: string
  proposedFirstStepName: string
  proposedJson: string
  currentJson: string
  status: string
  reviewers: string[]
  sourceVersion: number
  reason: string
}

export async function SubmitForApproval(props) {
  const response = await axios.request({
    method: 'POST',
    url: config.approvalEndpoint,
    data: {
      ops: 'SubmitForApproval',
      body: `{ 
        "currentJson": ${props.currentJson},
        "proposedJson": ${props.proposedJson},
        "proposedFirstStepName": "${props.proposedFirstStepName}",
        "requester": "${props.requester}",
        "sourceVersion": "${props.sourceVersion}",
        "treatmentId": "${props.treatmentId}",
        "clientId": "${props.clientId}"
      }`,
    },
    headers: {
      'content-type': 'application/json',
    },
  })

  return response.data
}

export async function GetRequestById(props) {
  const response = await axios.request({
    method: 'POST',
    url: config.approvalEndpoint,
    data: {
      ops: 'GetRequests',
      body: `{ 
        "requestId": "${props.requestId}"
      }`,
    },
    headers: {
      'content-type': 'application/json',
    },
  })

  console.log(response)
  return response.data[0]
}

export async function GetRequestsForTreatment(props) {
  const response = await axios.request({
    method: 'POST',
    url: config.approvalEndpoint,
    data: {
      ops: 'GetRequests',
      body: `{ 
        "treatmentId": "${props.treatmentId}",
        "clientId": "${props.clientId}"
      }`,
    },
    headers: {
      'content-type': 'application/json',
    },
  })

  return response.data
}

export async function ApproveRequest(props) {
  const response = await axios.request({
    method: 'POST',
    url: config.approvalEndpoint,
    data: {
      ops: 'UpdateRequestStatus',
      body: `{ 
        "requestId": "${props.requestId}",
        "status": "APPROVED",
        "userAlias": "${props.userAlias}"
      }`,
    },
    headers: {
      'content-type': 'application/json',
    },
  })

  return response.data
}

export async function RejectRequest(props) {
  const response = await axios.request({
    method: 'POST',
    url: config.approvalEndpoint,
    data: {
      ops: 'UpdateRequestStatus',
      body: `{ 
        "requestId": "${props.requestId}",
        "status": "REJECTED",
        "userAlias": "${props.userAlias}",
        "reason": "${props.reason}"
      }`,
    },
    headers: {
      'content-type': 'application/json',
    },
  })

  return response.data
}

export async function CancelRequest(props) {
  const response = await axios.request({
    method: 'POST',
    url: config.approvalEndpoint,
    data: {
      ops: 'UpdateRequestStatus',
      body: `{ 
        "requestId": "${props.requestId}",
        "status": "CANCELLED",
        "userAlias": "${props.userAlias}"
      }`,
    },
    headers: {
      'content-type': 'application/json',
    },
  })

  return response.data
}
