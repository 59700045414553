import { Config } from './Config'
import { Stage } from '../common/models/stage'

/**
 * Configuration overrides for Production environment.
 */
export class ProdConfig extends Config {
  public stage = Stage.PRODUCTION
  public safesEndpoint = `https://acx4ldgual.execute-api.us-east-1.amazonaws.com/jwt/safes`
  public brassEndpoint = `https://acx4ldgual.execute-api.us-east-1.amazonaws.com/jwt/brass`
  public approvalEndpoint = `https://acx4ldgual.execute-api.us-east-1.amazonaws.com/jwt/approval`
}
