import React, { useEffect, useState } from 'react'
import { ColumnLayout, Grid, Link, Spinner, Textarea } from '@amzn/awsui-components-react'
import { GetRequestById, Request } from '../../apis/ApprovalApi'
import { useNavigate, useParams } from 'react-router-dom'
import { Container, Header, Button, Box, SpaceBetween } from '@amzn/awsui-components-react/polaris'
import { ApproveModal, CancelModal, RejectModal } from './UpdateStatusModals'
import { IsAuthorized } from '../../apis/BRASSApi'
import { useSelector } from 'react-redux'
import { RootState } from '../../store/store'

export default function RequestPage() {
  const [request, setRequest] = useState<Request>()
  const [writeAuthorized, setWriteAuthorized] = useState(false)
  const { id } = useParams()
  const [modal, setModal] = useState(<div />)
  const userAlias = useSelector((state: RootState) => state.userInfo.userAlias)
  const navigate = useNavigate()

  const reset = () => {
    setModal(<div />)
    fetchRequest()
  }

  const isWriteAuthorized = () => {
    IsAuthorized({ userAlias, clientId: request?.clientId, operation: 'Write' }).then(
      (response) => {
        setWriteAuthorized(JSON.parse(response))
      }
    )
  }

  const fetchRequest = () => {
    GetRequestById({ requestId: id })
      .then((response) => {
        setRequest(response)
      })
      .catch((exception) => console.error('Problem: cannot get request', exception))
  }

  useEffect(fetchRequest, [id])
  useEffect(isWriteAuthorized, [id, userAlias, request?.clientId])

  const viewRuleset = (clientId, treatmentName) => {
    navigate(`/ruleset/diagram?client=${clientId}&ruleset=${treatmentName}`)
  }

  return (
    <Box margin="xxl" padding="xxl">
      {modal}
      <Grid gridDefinition={[{ colspan: 6, offset: { default: 3 } }]}>
        {!request ? (
          <Container header={<Header variant="h2">Request details</Header>}>
            <Spinner /> Loading
          </Container>
        ) : (
          <Container
            header={
              <Header
                variant="h2"
                info={
                  <Link onFollow={() => viewRuleset(request?.clientId, request?.treatmentId)}>
                    View ruleset
                  </Link>
                }
                actions={
                  request?.status === 'PENDING' ? (
                    <SpaceBetween direction="horizontal" size="xs">
                      <Button
                        onClick={() => {
                          setModal(<CancelModal requestId={id} dismissCallback={reset} />)
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={() => {
                          setModal(<RejectModal requestId={id} dismissCallback={reset} />)
                        }}
                        disabled={!writeAuthorized || request.requester === userAlias}
                      >
                        Reject
                      </Button>
                      <Button
                        variant="primary"
                        onClick={() => {
                          setModal(
                            <ApproveModal
                              requestId={id}
                              secondApproval={request?.reviewers && request.reviewers.length > 0}
                              dismissCallback={reset}
                            />
                          )
                        }}
                        disabled={
                          !writeAuthorized ||
                          request.requester === userAlias ||
                          (request?.reviewers && request.reviewers.includes(userAlias))
                        }
                      >
                        Approve
                      </Button>
                    </SpaceBetween>
                  ) : null
                }
              >
                Request details
              </Header>
            }
          >
            <ColumnLayout columns={3} variant="text-grid">
              <SpaceBetween size="l">
                <div>
                  <Box variant="awsui-key-label">Request</Box>
                  <div>{request?.requestId}</div>
                </div>
                <div>
                  <Box variant="awsui-key-label">Status</Box>
                  <div>{request?.status}</div>
                </div>
                <div>
                  <Box variant="awsui-key-label">Reason</Box>
                  <div>{request?.reason}</div>
                </div>
              </SpaceBetween>
              <SpaceBetween size="l">
                <div>
                  <Box variant="awsui-key-label">Client</Box>
                  <div>{request?.clientId}</div>
                </div>
                <div>
                  <Box variant="awsui-key-label">Ruleset</Box>
                  <div>{request?.treatmentId}</div>
                </div>
                <div>
                  <Box variant="awsui-key-label">Source ruleset version</Box>
                  <div>{request?.sourceVersion}</div>
                </div>
              </SpaceBetween>
              <SpaceBetween size="l">
                <div>
                  <Box variant="awsui-key-label">Requester</Box>
                  <div>{request?.requester}</div>
                </div>
                <div>
                  <Box variant="awsui-key-label">Reviewers</Box>
                  <div>{request?.reviewers && request.reviewers.join(', ')}</div>
                </div>
              </SpaceBetween>
            </ColumnLayout>
            <ColumnLayout columns={2} variant="text-grid">
              <SpaceBetween size="l">
                <div>
                  <Box variant="awsui-key-label">Source ruleset JSON</Box>
                  <div>
                    <Textarea
                      value={
                        request?.currentJson
                          ? JSON.stringify(JSON.parse(request.currentJson), null, 4)
                          : ''
                      }
                      disabled
                      rows={20}
                    />
                  </div>
                </div>
              </SpaceBetween>
              <SpaceBetween size="l">
                <div>
                  <Box variant="awsui-key-label">Proposed JSON</Box>
                  <div>
                    <Textarea
                      value={
                        request?.proposedJson
                          ? JSON.stringify(JSON.parse(request.proposedJson), null, 4)
                          : ''
                      }
                      disabled
                      rows={20}
                    />
                  </div>
                </div>
              </SpaceBetween>
            </ColumnLayout>
          </Container>
        )}
      </Grid>
    </Box>
  )
}
