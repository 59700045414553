import { FraudChecks } from './FraudChecks'

export const FraudCheckParameters = new Map<string, Map<string, string[]>>([
  [FraudChecks.BitsCheck, new Map<string, string[]>([['maxPurchaseDaysLimit', ['30']]])],
  [
    FraudChecks.ChargeStatusCheck,
    new Map<string, string[]>([
      ['allowedProcessorStatusCodes', []],
      ['chargeBypassedPaymentCategory', []],
    ]),
  ],
  [
    FraudChecks.DigitalOrdersCheck,
    new Map<string, string[]>([
      ['maxPurchaseDaysLimit', ['180']],
      ['minPurchaseDaysLimit', ['30']],
    ]),
  ],
  [
    FraudChecks.DirectDebitCheck,
    new Map<string, string[]>([
      ['invalidBankAccounts', []],
      ['freeTrialInvalidBankAccounts', []],
      ['paidInvalidBankAccounts', []],
    ]),
  ],
  [FraudChecks.EmandateCheck, new Map<string, string[]>([['validSubscriptionTags', []]])],
  [
    FraudChecks.HighVelocityIssuingBankCheck,
    new Map<string, string[]>([['highVelocityBlockingLayers', []]]),
  ],
  [
    FraudChecks.IssuingBankCheck,
    new Map<string, string[]>([
      ['invalidIssuingBanks', []],
      ['invalidNullIssuingBankCountryCodes', []],
    ]),
  ],
  [FraudChecks.MobileTokenLimitCheck, new Map<string, string[]>([['maxTokenLimit', ['3']]])],
  [
    FraudChecks.PhysicalOrdersCheck,
    new Map<string, string[]>([
      ['minPurchaseOrders', ['2']],
      ['maxPurchaseDaysLimit', ['180']],
      ['minPurchaseDaysLimit', ['30']],
    ]),
  ],
  [
    FraudChecks.RequiredPrimeBenefitsCheck,
    new Map<string, string[]>([
      ['requiredFacets', []],
      ['invalidFacets', []],
    ]),
  ],
  [FraudChecks.RiskContextCheck, new Map<string, string[]>([['invalidRiskContexts', []]])],
  [FraudChecks.TokenLimitCheck, new Map<string, string[]>([['maxTokenLimit', ['3']]])],
  [
    FraudChecks.TwoFactorAuthenticationCheck,
    new Map<string, string[]>([
      ['blockedMobileCountryCodes', []],
      ['enforceLocalNumbersForEuMfa', ['false']],
      ['enforceLocalNumbersForBancontact', ['false']],
      ['fraudCheckReasonsToEnforce2fa', []],
      // Default to values to the current setting, see eligible payment category enum in https://tiny.amazon.com/197k7iiot/codeamazpackPaySblob5dd8open
      ['tfaBypassedPaymentCategory', ['DigitalWallet']],
      ['tfaBypassedWithLimitedTokenPaymentCategory', ['Bancontact', 'UnifiedPaymentsInterface']],
      ['tfaRequiredPaymentCategory', []],
    ]),
  ],
])
