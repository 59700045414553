import { combineReducers, configureStore } from '@reduxjs/toolkit'
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import layoutFlowReducer from '../store/slice/LayoutFlowSlice'
import userInfoReducer from '../store/slice/UserInfoSlice'

const persistConfig = {
  key: 'root',
  storage,
  // redux-persist has an issue open to change property below to use inclusive language
  whitelist: ['userInfo'],
}

const rootReducer = combineReducers({
  layoutFlow: layoutFlowReducer,
  userInfo: userInfoReducer,
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore the actions from redux-persist
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
})

export const persistor = persistStore(store)

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
